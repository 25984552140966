import { SelectionModel } from '@angular/cdk/collections';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  HostListener,
  Inject,
  NgZone,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { MatIconRegistry } from '@angular/material/icon';
import { MatMenu, MatMenuTrigger } from '@angular/material/menu';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { DomSanitizer } from '@angular/platform-browser';
import firebase from 'firebase/app';
import { CustomActions } from 'src/app/dictionaries/CustomActions';
import { UserRoles } from 'src/app/dictionaries/UserRoles';
import { ContextMenuModel } from 'src/app/models/ContextMenu';
import { ClientMatterService } from 'src/app/services/client-matter.service';
import { ClioService } from 'src/app/services/clio.service';

import { MatExpansionPanel } from '@angular/material/expansion';
import { DialogService } from 'src/app/dialog.service';
import { folderColors } from 'src/app/dictionaries/folderColors';
import { NotificationTypes } from 'src/app/models/NotificationTypes';
import { NotificationsService } from 'src/app/notifications.service';
import { CookiesService } from 'src/app/services/cookies.service';
import { environment } from '../../../environments/environment';
import { UploadTypes } from '../../models/UploadTypes';
import { AuthService } from '../../services/auth.service';
import { FilesService } from '../../services/files.service';
import { FirebaseUtilitiesService } from '../../services/firebase-utilities.service';
import { GapiOperationsService } from '../../services/gapi-operations.service';
import { PermissionsService } from '../../services/permissions.service';
import { UIMessagingService } from '../../services/uimessaging.service';
import { UploadHelperService } from '../../services/upload-helper.service';
import { UsersService } from '../../services/users.service';
import { UtilsService } from '../../services/utils.service';
import { ActiveConsultantsComponent } from '../active-consultants/active-consultants.component';
import { AvailableConsultantsComponent } from '../available-consultants/available-consultants.component';
import { CreateCuaUserComponent } from '../create-cua-user/create-cua-user.component';
import { EditFileDialogComponent } from '../edit-file-dialog/edit-file-dialog.component';
import { InfoWindowComponent } from '../info-window/info-window.component';
import { NewFolderComponent } from '../new-folder/new-folder.component';
import { PracticePantherListMattersComponent } from '../practicepanther/PracticePantherListMatters/PracticePantherListMatters.component';
import { SearchFilesComponent } from '../search-files/search-files.component';
import { AllNotesDialogComponent } from '../ui/all-notes-dialog/all-notes-dialog.component';
import { ConfirmationDialogComponent } from '../ui/confirmation-dialog/confirmation-dialog.component';
import { DocViewerComponent } from '../ui/doc-viewer/doc-viewer.component';
import { NoteDialogComponent } from '../ui/note-dialog/note-dialog.component';
import { SimpleMessageWindowComponent } from '../ui/simple-message-window/simple-message-window.component';
import { UploadDialogComponent } from '../ui/uploaddialog/uploaddialog.component';
import { FileActionsDictionary } from './../../dictionaries/FileActions';
import { LogLevelsDictionary } from './../../dictionaries/LogLevels';
import { LogService } from './../../log.service';
import { AlgoliaService } from './../../services/algolia.service';
import { SharingFilesService } from './../../sharing-files.service';
import { ClioListMattersComponent } from './../clio/ClioListMatters/ClioListMatters.component';
import { ImportAppsComponent } from './../import-apps/import-apps.component';
import { FolderSelectorComponent } from './../ui/folder-selector/folder-selector.component';
import { VOBInfoWindowComponent } from './../vob-info-window/vob-info-window.component';
import { ViewAllNotesDialogData } from 'src/app/models/ViewAllNotesDialogData';
import { FileCommentsAttachmentsService } from 'src/app/services/file-comments-attachments.service';
import { FoldersService } from 'src/app/services/folders.service';
import { ClientProfileToolbarComponent } from './../ui/client-profile-toolbar/client-profile-toolbar.component';
import { SessionStorageService } from 'src/app/services/session-storage.service';
import { HowToBurnADiscComponent } from '../ui/how-to-burn-a-disc/how-to-burn-a-disc.component';
import { CreateuserComponent } from '../createuser/createuser.component';

declare function require(name: string);
declare function dumpFile(file: any, option: string): [];

const DISCS_FOLDER_NAME = 'Discs';
const HOME_FOLDER_NAME = '';
const INDEX_FOLDER_NAME = 'all';
const CLIO_FOLDER_NAME = 'Clio';

const localFolderColors = ['rgb(187, 222, 251)', 'rgb(209, 196, 233)', 'rgb(255, 224, 130)'];
const indexFolderColor = '#eadbff';
const indexFolderLabel = 'INDEX';
const unshareIconUrl = './../../assets/svg/unshare.svg';
const notPlayableVideoExtensions = ['mpeg', 'vob'];
const permissionsNeeded = [
  'uploadfiles',
  'editfiles',
  'deletefiles',
  'consultantslist',
  'sharefileicon',
  'createfolder',
];

const viewerUrlParts = {
  location: 4,
  dataSet: 6,
  dicomStore: 8,
  studyUID: 10,
};

export interface FolderElement {
  name: string;
  id: string;
  color: string;
}

export interface Consultant {
  companyName: string;
  createdBy: string;
  email: string;
  filesSharedWith?: any[];
  name: string;
  ownerID: string;
  passwordExpirationDate: string;
  role: string;
  uid: string;
  shared: boolean;
}

export interface Note {
  title: any;
  note: any;
  createdBy: any;
  attachments: any[];
  noteId: string;
}

export interface NoteFile {
  notes: Note[];
  attachments: any[];
  fileId: string;
  id: any;
  type: any;
  parentFolder: any;
}

export interface NoteObject {
  attachments: any;
  name: string;
  description: string;
  useremail: string;
  noteId: string;
  visibility?: any;
}

@Component({
  templateUrl: './clientprofile.component.html',
  styleUrls: ['./clientprofile.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ClientProfileComponent implements OnInit, AfterViewChecked {
  discsFolderName = DISCS_FOLDER_NAME;
  uploadTypes = this.getUploadTypes();
  generateDiscLabel = 'GENERATE DISC';
  downloadDiscLabel = 'DOWNLOAD DISC FILES';
  filesFilter = { name: '', valid: false };
  nofilesMessage = 'No files have been uploaded for this folder yet.';
  unshareAllFilesMsg = 'UNSHARE all shared files with this Consultant';
  activeConsultant = null;
  lastModified = 'lastModified';
  displayedColumns: string[] = [
    'select',
    'fileName',
    // 'ftype',
    'predefined',
    'fileDesc',
    'fdate',
    this.lastModified,
    'uploadedDate',
    'parentFolderName',
    'fileId',
    'isShared',
  ];

  fileActionsMenuItems = [];

  user: any;
  patient: any = false;
  fdesc: string;
  fdate: string;
  ftime: string;

  ftype: string;

  files: any[] = [];
  patientName = '...';
  currentFolder = '';
  url: string;

  fileName: string;
  uid: string;
  currentFileOpen: any;
  allowAddNote: boolean;
  allowDeleteNote: boolean;
  realRole: string;
  selectedFile: string;

  width: string;
  casename: string;
  storename: string;
  selection = new SelectionModel<any>(true, []);

  @ViewChild('picker', { static: true }) picker;
  @ViewChild(ClientProfileToolbarComponent, { static: false })
  clientProfileToolbarComponent: ClientProfileToolbarComponent;
  filesTableDataSource = new MatTableDataSource<any>(this.files);
  sortedData = this.files.slice();
  @ViewChild(MatPaginator) uppaginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  clientDataStores: any;
  ownerID: any;

  activeConsultants: any[];
  availableConsultants: any[];

  loadingPermissions = true;
  permissions_uploadfiles: any;
  permissions_editfiles: any;
  permissions_deletefiles: any;
  permissions_consultantslist: any;
  permissions_sharefileicon: any;
  permissions_createfolder: any;

  @ViewChild(ActiveConsultantsComponent) active_consultants: ActiveConsultantsComponent;
  @ViewChild(AvailableConsultantsComponent) available_consultants: AvailableConsultantsComponent;

  refreshButton = true;
  refreshing = false;
  getFilesForPatientRunning: Promise<void>;
  defaultFolders: boolean;

  sortTypes: any[] = [
    { value: 'fdate', direction: 'desc', label: 'Date of file' },
    { value: 'ftype', direction: 'desc', label: 'File type' },
    { value: 'fileDesc', direction: 'desc', label: 'File content' },
    { value: 'lastModified', direction: 'desc', label: 'Last Modified' },
    { value: 'uploadedDate', direction: 'desc', label: 'Date Uploaded' },
  ];

  /**
   * BASIC CONTEXT MENU
   */
  title = 'context-menu';
  isDisplayContextMenu: boolean;
  rightClickMenuItems: Array<ContextMenuModel> = [];
  matMenu: MatMenu;
  // reference to the MatMenuTrigger in the DOM
  @ViewChild(MatMenuTrigger, { static: true }) matMenuTrigger: MatMenuTrigger;
  @ViewChild('helpPanel') helpPanel: MatExpansionPanel;
  @ViewChild('howToBurnADisc') howToBurnADisc: HowToBurnADiscComponent;
  menuTopLeftPosition = { x: '0', y: '0' };
  selected = 0;
  foldersList: any;
  currentFolderName: any;
  caseFolders: any[];
  pageSize: number;
  pageSizes: number[];
  objectsCount: number;
  pagesLength: number;
  selectionRecord: any;
  folderColor: string;
  sharedFolders: any[];
  viewNotesButton: boolean;
  indexFolderName: any;
  UserRoles: {
    owner: string;
    admin: string;
    associate: string;
    consultant: string;
    superuser: string;
    client: string;
  };
  FileActionsDictionary: typeof FileActionsDictionary;
  pickedSharedFile = {};
  selectedTabIndex: number;
  loading: boolean;
  loadingMessage: string;
  importFromAppsBtn = true;
  button: any;
  infectedFiles = [];
  section: any;
  clioMatterId: any;
  clioDocumentIdToBeOpened: string;
  targetFolder: any;
  homeFolderName = '';
  userIsConsultant: boolean;
  userIsClient: boolean;
  dicomdirFileName: string;
  plancode: string;
  ownerHasAccess: boolean = false;
  sharedFilesCount: number;
  sysadmin = environment.config.sysadmin;
  discsFolderFlow: any = false;
  clientProfileNotifications: any = [];
  notificationIds: string[] = [];
  ownerTotalStudies: Promise<any>;
  ownerPlanCode: any;

  constructor(
    private ngZone: NgZone,
    private auth_$: AuthService,
    private cookies_$: CookiesService,
    private algolia_$: AlgoliaService,
    private permissions_$: PermissionsService,
    private firebase_utilities_$: FirebaseUtilitiesService,
    private gapiOperations_$: GapiOperationsService,
    private uploadHelper_$: UploadHelperService,
    private utils_$: UtilsService,
    private files_$: FilesService,
    private folders_$: FoldersService,
    private sharing_files_$: SharingFilesService,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    public ref: ChangeDetectorRef,
    public dialogRef: MatDialogRef<ClientProfileComponent>,
    private dialog_$: DialogService,
    private dialog: MatDialog,
    private log_$: LogService,
    private users_$: UsersService,
    private http: HttpClient,
    private clientMatter_$: ClientMatterService,
    private uiMessaging_$: UIMessagingService,
    private clio_$: ClioService,
    private notifications_$: NotificationsService,
    private fileCommentsAttachments_$: FileCommentsAttachmentsService,
    private sessionStorage_$: SessionStorageService,
    @Inject(MAT_DIALOG_DATA) data,
  ) {
    const { patient, button, section, clioMatterId, clioDocumentId, targetFolder } = data;
    this.dicomdirFileName = 'DICOMDIR';
    this.targetFolder = targetFolder || null;
    this.clioDocumentIdToBeOpened = clioDocumentId;
    this.selectedTabIndex = 0;
    this.FileActionsDictionary = FileActionsDictionary;
    this.UserRoles = {
      owner: UserRoles.owner,
      client: UserRoles.client,
      admin: UserRoles.admin,
      associate: UserRoles.associate,
      consultant: UserRoles.consultant,
      superuser: UserRoles.superuser,
    };
    this.viewNotesButton = false;
    this.pageSize = 0;
    this.pageSizes = [5, 10, 20, 40, 80, 100];
    this.matIconRegistry.addSvgIcon('unshareIcon', this.domSanitizer.bypassSecurityTrustResourceUrl(unshareIconUrl));
    this.patient = patient;
    this.auth_$.setCurrentPatient(patient);
    this.patientName = this.getPatientName(patient);
    this.casename = this.patient.caseName;
    this.defaultFolders = Boolean(this.patient.defaultFolders);
    this.files = [];
    this.allowAddNote = true;
    this.allowDeleteNote = true;
    this.fdesc = '';
    this.fdate = this.utils_$.getCurrentDate();
    this.ftime = null;
    this.ftype = '';
    this.sortedData = this.files.slice();
    this.button = button;
    this.section = section || null;
    this.clioMatterId = clioMatterId || patient.clioMatterId || patient.clioDocId;
  }

  async ngOnInit() {
    this.fileActionsMenuItems.push({
      label: 'COPY TO',
      icon: 'content_copy',
      class: '',
      action: () => this.selectTarget(FileActionsDictionary.COPY),
      condition: () => this.currentFolderName !== DISCS_FOLDER_NAME,
    });
    this.fileActionsMenuItems.push({
      label: 'MOVE TO',
      icon: 'content_paste',
      class: '',
      action: () => this.selectTarget(FileActionsDictionary.MOVE),
      condition: () =>
        this.currentFolder !== 'all' &&
        this.currentFolderName !== CLIO_FOLDER_NAME &&
        this.currentFolderName !== DISCS_FOLDER_NAME,
    });

    this.subscribeToClientProfileNotifications();
    const folderZipIcon = 'folder_zip';

    // In your component
    this.fileActionsMenuItems.push({
      label: this.generateDiscLabel,
      icon: folderZipIcon,
      class: '',
      action: (event: Event) => this.generateDISC(event, this.selection.selected),
      condition: () => this.currentFolderName !== CLIO_FOLDER_NAME && this.selectedIsStudy(),
    });

    this.fileActionsMenuItems.push({
      label: this.downloadDiscLabel,
      icon: folderZipIcon,
      class: 'red-text',
      action: event => {
        this.handleFileClick(event, this.selection.selected[0]);
        this.hideFileActions();
      },
      condition: () => this.currentFolderName !== CLIO_FOLDER_NAME && this.selectedIsZippedDisc(),
    });

    this.sessionStorage_$.clearStoppedGeneratedDiscAlert();
    this.getOwnerAccess();
    this.plancode = this.auth_$.userData.getValue()['plancode'];
    this.auth_$.showLoader('Loading the Client/Matter ...');

    // FIXME: #197 Have to move this Getting Permissions to the afterLogin moment instead of wait til here to do the request.
    this.setPermissions();

    this.user = this.auth_$.userData.getValue()['user'];
    this.realRole = this.auth_$.userData.getValue()['realRole'];
    this.userIsConsultant = this.realRole === UserRoles.consultant;
    this.userIsClient = this.realRole === UserRoles.client;
    this.allowAddNote = this.allowDeleteNote = !this.userIsConsultant;
    this.uploadHelper_$.scannedBlocksSource.next([]); // Clean scanned blocks.

    // this.caseOwnerId = this.auth_$.userData.getValue()['ownerID'];

    this.subscribeToFilesAction();
    this.subscribeToInfectedFiles();
    this.subscribeToClientProfileActions();
    this.subscribeToClientProfileFiles();

    if ([UserRoles.owner, UserRoles.admin, UserRoles.associate].includes(this.user.role)) {
      window.onclick = event => {
        event.preventDefault();
        this.cleanHighlighted();
        this.showSharedConsultantsByFile({});
      };
    }

    this.uid = this.auth_$.uid;
    this.activeConsultant = null;

    // const definedDefaultFolders = (await this.getDefinedDefaultFolders(this.realRole)).map(folder => folder.name);
    this.caseFolders = await this.handlingFolders();

    this.subscribeToFileUploads();
    this.selection.clear();

    // REVIEW: #117 Improve this!! WIP
    // this.firebase_utilities_$.getClientCreatorEmailByCaseName(this.casename).then(data => (this.patientCreator = data));
    this.ownerID = this.getOwnerID();
    this.ownerTotalStudies = await this.getOwnerTotalStudies();
    this.ownerPlanCode = this.getOwnerPlanCode();

    this.handleActiveButton(this.button);
    this.auth_$.hideLoader();

    if (this.targetFolder) {
      await this.browseFolderByName(this.targetFolder);
    }

    if (this.section) {
      await this.openSection(this.section, this.clioMatterId);
    } else {
      await this.getData();
    }

    this.auth_$.hideLoader();
  }

  getUploadTypes() {
    return [
      { id: '1', name: UploadTypes.FILE },
      { id: '2', name: UploadTypes.VIDEO },
      { id: '3', name: UploadTypes.DICOMDisk },
      { id: '4', name: UploadTypes.VIDEODisk },
    ];
  }

  getPatientName({ FirstName, LastName }): string {
    return `${FirstName.charAt(0).toUpperCase()}${FirstName.slice(1)} ${LastName.charAt(
      0,
    ).toUpperCase()}${LastName.slice(1)}`;
  }

  async handlingFolders() {
    await this.checkCreateDefaultFolders();
    const getFoldersList = await this.firebase_utilities_$.getFolders(this.casename);
    return getFoldersList;
  }

  async addConsultant() {
    console.log('ngOnInit 228 -- getFilesForPatient');
    const limits = await this.checkPlanLimits();
    const r_dialog = this.dialog_$.open(CreateuserComponent, {
      width: '680px',
      data: {
        userType: 'Admin',
        title: 'Create Admin',
        button: 'Create',
        screen: 'full',
        limits: { limits: limits },
      },
    });
    r_dialog.afterClosed().subscribe(async result => {
      this.availableConsultants = await this.getAvailableConsultants();
    });
  }

  async checkPlanLimits() {
    const [limits, users] = await Promise.all([
      this.getPlanLimits(this.auth_$.userData.value['plancode']),
      this.getUsersCount(),
    ]);
    return { limits, users };
  }

  private getUsersCount() {
    return this.auth_$.getUsersCount();
  }

  private getPlanLimits(planCode: string) {
    return this.auth_$.getPlanLimits(planCode).then(limits => {
      return limits;
    });
  }

  handleActiveButton(button) {
    if (button) {
      switch (button) {
        case 'clio-matters':
          this.showImportFromApps('clio');
          break;
        case 'practicepanther-matters':
          this.showImportFromApps('practicepanther');
          break;
        default:
          break;
      }
    }
  }

  // FIXME: Use the default folders obtained here to avoid to get them later in the code.
  async checkCreateDefaultFolders() {
    // NOTE: Is the current client profile doesn't have predefined folder, this piece of code would create them.
    if (!this.defaultFolders && [UserRoles.owner, UserRoles.admin, UserRoles.associate].includes(this.realRole)) {
      if (!(await this.firebase_utilities_$.getDefaultFoldersFlag(this.casename))) {
        await this.createDefaultFolders();
        console.log('Default folders created.');
      }
    }
  }

  subscribeToClientProfileNotifications() {
    this.auth_$.clientProfileNotifications.subscribe(
      notifications => (this.clientProfileNotifications = notifications),
    );
  }

  onNotificationClick({ action, id }) {
    console.log('onNotificationClick: ', action, id);

    if (action === 'ok') {
      this.auth_$.clientProfileActionsData = { fileId: id };
      this.auth_$.clientProfileActions.next('gotodiscs');
    } else {
      {
        console.log('Mark as read');
        this.auth_$.disableUserDataNewDownloadNotification(id);
        this.auth_$.disableFirestoreNewDownloadNotification(id);
      }
    }

    this.clientProfileNotifications = this.clientProfileNotifications.filter(notification => notification.id !== id);
  }

  subscribeToInfectedFiles() {
    this.firebase_utilities_$.infectedFiles.subscribe(x =>
      this.infectedFiles.length > 0 ? this.showInfectedFiles(this.infectedFiles) : null,
    );
  }

  subscribeToFilesAction() {
    if ([UserRoles.admin, UserRoles.owner, UserRoles.superuser].includes(this.user.role)) {
      this.uploadHelper_$.action.subscribe({
        next: action => {
          if (action === 'update') {
            console.log('Updating list...');
            console.log('ngOnInit 322 -- getFilesForPatient');
            this.getFilesForPatient(this.currentFolder, {
              caseFolders: this.caseFolders,
              roleConsultant: this.userIsConsultant,
              roleClient: this.userIsClient,
            });
          }
        },
        error: error => console.log('An error has been reported in action.', error),
        complete: () => console.log('completed.'),
      });
    }
  }

  subscribeToFileUploads() {
    if ([UserRoles.owner, UserRoles.admin, UserRoles.associate].includes(this.user.role)) {
      // NOTE: Set an observer to fetch files after each file upload.
      this.uploadHelper_$.fileUploaded.toPromise().then(obs => {
        if (obs) {
          console.log('ngOnInit 360 -- getFilesForPatient');
          this.getFilesForPatient(this.currentFolder, {
            folderName: this.getFolderName(this.caseFolders, this.currentFolder),
            caseFolders: this.caseFolders,
            roleConsultant: this.userIsConsultant,
            roleClient: this.userIsClient,
          });
        }
      });
    }
  }

  async clientProfileReload(event) {
    console.log('getData()');
    await this.getData();
  }

  backToClio(matterId: any) {
    window.open(`https://app.clio.com/nc#/matters/${matterId}`, '_self');
  }

  openDocumentInClio(documentId) {
    window.open(`https://app.clio.com/nc#/documents/${documentId}/details`, '_self');
  }

  removeFilter() {
    this.filesFilter = { name: '', valid: false };
    console.log('removeFilter -- getFilesForPatient');
    this.getFilesForPatient(this.currentFolder, {
      roleConsultant: this.userIsConsultant,
      roleClient: this.userIsClient,
    });
  }

  getProperViewer(fileName) {
    const extension = fileName.split('.').pop().toLowerCase();
    let viewer = 'google';
    switch (extension) {
      case 'mp4':
      case 'avi':
        viewer = 'video';
        break;
      case 'pdf':
        viewer = 'google';
        break;
      case 'doc':
      case 'docx':
      case 'xls':
      case 'xlsx':
      case 'ppt':
      case 'pptx':
      case 'csv':
        viewer = 'office';
        break;
      case 'png':
      case 'jpg':
      case 'jpeg':
      case 'gif':
        viewer = 'url';
        break;
      default:
        viewer = 'google';
        break;
    }
    return viewer;
  }

  async previewDocument(filePath: string, fileName: string) {
    this.auth_$.showLoader('Loading the document ...');
    const fileUrl = await this.files_$.getFileUrl(filePath);

    if (!fileUrl) this.uiMessaging_$.toastMessage('Error getting the file URL', 'ERROR');

    this.dialog_$
      .open(DocViewerComponent, {
        width: '80%',
        data: {
          src: fileUrl.data.url,
          title: fileName,
          info: fileUrl.data.url,
          viewer: this.getProperViewer(fileName),
        },
      })
      .afterOpened()
      .subscribe(() => this.auth_$.hideLoader());
  }

  offerToImportClioDocument(clioDocumentId) {
    this.dialog_$
      .open(SimpleMessageWindowComponent, {
        data: {
          title: 'Import Document',
          message: 'This document does not exist in the current Client/Matter. Would you like to import it?',
          buttons: [
            { text: 'Yes', value: true },
            { text: 'No', value: false },
          ],
        },
      })
      .afterClosed()
      .subscribe({
        next: async res => {
          if (res) {
            await this.importClioDocument(clioDocumentId);
            console.log('offerToImportClioDocument -- getFilesForPatient');
            this.getFilesForPatient(this.currentFolder, {
              caseFolders: this.caseFolders,
              roleConsultant: this.userIsConsultant,
              roleClient: this.userIsClient,
            });
          } else {
            console.log('User declined to import this document.');
          }
        },
        error: error => console.log('An error has been reported in offerToImportClioDocument.', error),
        complete: () => console.log('completed.'),
      });
  }

  getClioFolderId(caseFolders) {
    console.log('caseFolders :', caseFolders);
    const clioFolder = caseFolders.find(folder => folder.name === CLIO_FOLDER_NAME);
    return clioFolder.folderId;
  }

  async importClioDocument(clioDocumentId) {
    this.auth_$.showLoader('Importing document from Clio...');
    const clioDocument = await this.clientMatter_$.importClioDocument(
      clioDocumentId,
      this.casename,
      this.auth_$.userData.getValue()['id'],
      this.getClioFolderId(this.caseFolders),
    );
    this.auth_$.hideLoader();
    if (clioDocument['data'] === null) {
      this.uiMessaging_$.toastMessage('Error importing document from Clio.', 'ERROR');
    } else {
      this.openDocument(clioDocument.data);
    }
  }

  openDocument(clioDocument) {
    this.handleFileClick(new Event('click'), clioDocument.data);
  }

  async openSection(section, clioMatterId) {
    switch (section) {
      case CustomActions.openClioDocument:
        if (this.clioDocumentIdToBeOpened) {
          const clioDocument = await this.clientMatter_$.checkIfClioDocumentExists(
            this.clioDocumentIdToBeOpened,
            this.casename,
          );
          if (clioDocument) {
            this.openDocument(clioDocument);
            return false;
          }

          // Offer to import this document from Clio.
          this.offerToImportClioDocument(this.clioDocumentIdToBeOpened);
        }
        break;
      case CustomActions.uploadDicomDisk:
        this.filesFilter = { name: 'DICOM', valid: true };
        await this.handleFolderClick(new Event('click'), { name: 'DICOM ', folderId: 'all', predefined: true });
        this.openDialog({ section, clioMatterId });
        break;
      case CustomActions.viewMedicalImages:
        this.filesFilter = { name: 'DICOM', valid: true };
        await this.handleFolderClick(new Event('click'), { name: 'DICOM ', folderId: 'all', predefined: true });
        break;
      case CustomActions.openClioMatter:
      case CustomActions.openClioMatterFromDocument:
        console.log(CustomActions.openClioMatter);
        // await this.getData();
        break;
      case CustomActions.goToNuageDx:
        console.log(CustomActions.goToNuageDx);
        // await this.getData();
        break;
      default:
        break;
    }
  }

  async getData() {
    console.log('getData :');

    // NOTE: Start getting the files.
    await this.getFilesForPatient(this.currentFolder, {
      folderName: this.getFolderName(this.caseFolders, this.currentFolder),
      roleConsultant: this.userIsConsultant,
      roleClient: this.userIsClient,
      caseFolders: this.caseFolders,
    });

    this.updatePaginator();

    if (!this.userIsConsultant && !this.userIsClient) {
      this.updateConsultantsLists();
    }
  }

  addSharedFlags(consultantsList: Consultant[], sharedUsers) {
    if (!consultantsList) return [];
    consultantsList.forEach(consultant => {
      consultant.shared = false;
      if (sharedUsers) {
        sharedUsers.forEach(user =>
          consultant.email.toLowerCase() === user.email.toLowerCase() ? (consultant.shared = true) : null,
        );
      }
    });
    return consultantsList;
  }

  handleFolderNameClick(event: { preventDefault: () => void }, element: { parentFolderName: any; parentFolder: any }) {
    event.preventDefault();
    this.browseFolderByName(element.parentFolderName || element.parentFolder);
  }

  async browseFolderByName(folderName: string, defaultSort?: string) {
    const folderId = await this.getFolderId(folderName);
    // const folderId = await this.getFolderId(folderName === 'Discs' ? 'Discs files' : folderName);
    if (!folderId) {
      console.error('Folder not found.', folderName);
      throw new Error('Folder not found.');
    }

    return this.browseFolderContents(folderId, folderName, defaultSort || null).then(() => {
      this.currentFolderName = folderName;
      this.currentFolder = folderId;
    });
  }

  checkClioAuthToken() {
    return this.auth_$.userData.getValue()['clioAccessToken'];
  }

  checkPracticePantherAuthToken(): string {
    return this.auth_$.userData.getValue()['practicepantherAccessToken'];
  }

  /**
   * Deactivates the active consultant, clears files selection and getFiles for the patient based on a folderId.
   * @param folderId if the folder you intend to browse.
   * @param folderName string name.
   */
  async browseFolderContents(folderId: string, folderName: string, defaultSort?: string) {
    const options = {
      globalFolders: true,
      folderName: folderName,
      roleConsultant: this.userIsConsultant,
      roleClient: this.userIsClient,
      caseFolders: this.caseFolders,
      defaultSort: defaultSort,
    };

    this.activeConsultant = null;
    this.selection.clear();
    this.auth_$.setCurrentFolder(folderName);
    await this.getFilesForPatient(folderId, options, true);

    if (defaultSort) this.handleSortData({ active: defaultSort, direction: 'desc' });

    if (!this.userIsConsultant) this.updateConsultantsLists();

    if (this.currentFolderName === DISCS_FOLDER_NAME) {
      // this.displayedColumns = this.displayedColumns.filter(column => ![this.lastModified, 'fileId'].includes(column));
      this.sortTypes = this.sortTypes.filter(sort => !['ftype', 'lastModified'].includes(sort.value));
      this.sortTypes.find(sort => sort.value === 'uploadedDate').label = 'Date Created';
    }
  }

  /**
   * Check if the given viewerurl is forbidden to be browsed.
   * @param viewerurl the url to be destructured.
   * @returns boolean
   */
  checkInactiveStore(element: any): boolean {
    if (!element || !element.viewerurl) {
      return;
    }
    const storename = this.utils_$.viewerurlToDicomStore(element.viewerurl, viewerUrlParts.dicomStore);
    const casename = this.utils_$.viewerurlToDicomStore(element.viewerurl, viewerUrlParts.dataSet);
    if (!this.uploadHelper_$.unAvailableStores.length) {
      return;
    }

    const isUnAvailable = this.uploadHelper_$.unAvailableStores.filter(el => {
      const sameStore = el.storename === storename;
      const sameCase = el.casename === casename;
      if (sameCase && sameStore) {
        return true;
      } else {
        return;
      }
    });

    return isUnAvailable.length > 0;
  }

  checkIfSelected(file: { fileId: any }) {
    for (let index = 0; index < this.selection.selected.length; index++) {
      if (this.selection.selected[index].fileId === file.fileId) {
        return true;
      }
    }
    return false;
  }

  /**
   * Check if any of the files in the list has any shared user that match with the given consultant email.
   */
  checkIfAnyShared(filesList: any[], consultantemail: string): boolean {
    let stillAnyShared = false;
    for (let index = 0; index < filesList.length; index++) {
      for (let innerIndex = 0; innerIndex < filesList[index].sharedUsers.length; innerIndex++) {
        const sharedUser = filesList[index].sharedUsers[innerIndex].email.toLowerCase();
        if (sharedUser === consultantemail.toLowerCase()) stillAnyShared = true;
      }
    }
    return stillAnyShared;
  }

  clearActiveConsultantsSelected() {
    this.activeConsultants = this.activeConsultants;
  }

  clearSharedFlags(consultantsList: any[]) {
    consultantsList.forEach(consultant => delete consultant.shared);
    return consultantsList;
  }

  private async checkDefaultFoldersV2(caseName: string): Promise<boolean> {
    const defaultFolders = [
      { name: 'Damages', label: '/Damages/', color: '#ffc1c1', type: 'predefined' },
      { name: 'Discovery', label: '/Discovery/', color: '#ffda81', type: 'predefined' },
      { name: 'Liability', label: '/Liability/', color: '#d1f3c3', type: 'predefined' },
      { name: 'Other', label: '/Other/', color: '#e7e7e7', type: 'predefined' },
    ];
    const _defaultFolders = defaultFolders.map(folder => folder.name);
    const dbFiles = firebase.firestore().collection('files');
    const result = await dbFiles
      .where('belongsTo', '==', caseName)
      .where('type', '==', 'folder')
      .where('name', 'in', _defaultFolders)
      .get();
    return !result.empty;
  }

  async createDefaultFolders() {
    const defaultFoldersCheck = await this.checkDefaultFoldersV2(this.casename);
    if (defaultFoldersCheck) return true;

    const casename = this.casename;
    const defaultFolders = await this.getDefaultFolders();
    const promisesStack = [];

    defaultFolders.forEach(folder =>
      promisesStack.push(
        this.firebase_utilities_$.createFolder({
          children: '',
          name: folder.name,
          color: folder.color,
          parentFolder: '',
          type: 'folder',
          belongsTo: casename,
          predefined: true,
          forDelete: false,
          folderId: this.uploadHelper_$.getRandomString(20),
        }),
      ),
    );

    const promisesResult = await Promise.all(promisesStack);
    this.defaultFolders = await this.firebase_utilities_$.updateDefaultFolders(true, { casename: this.casename });
  }

  cleanHighlighted() {
    document.querySelectorAll('.mat-column-fileName .mat-icon').forEach(item => item.classList.remove('on'));
  }

  async copySelectedTo(folderId: string) {
    const folderName = this.caseFolders.find(folder => folder.folderId === folderId).name;
    return this.firebase_utilities_$.createFirestoreFilesDuplicates(this.selection.selected, { folderId, folderName });
  }

  compare(a: number | string, b: number | string, isAsc: boolean) {
    if (typeof a === 'string' && typeof b === 'string')
      return (a.trim().toLowerCase() < b.trim().toLowerCase() ? -1 : 1) * (isAsc ? 1 : -1);
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  compareDates(a: number | string, b: number | string, isAsc: boolean) {
    return (new Date(a).getTime() < new Date(b).getTime() ? -1 : 1) * (isAsc ? 1 : -1);
  }

  /**
   * Check if emails from the @param usersArr are existing users, otherwise
   * this email(s) will be returned back.
   */
  async clearNonExistentUsers(usersArr: string[]): Promise<{ unLinked: string[]; notExistent: string[] }> {
    const unLinked = [];
    const notExistent = [];
    for (let index = 0; index < usersArr.length; index++) {
      const userEmail = usersArr[index];
      const found = (await this.users_$.getUserByEmail(userEmail)).docs.length;
      if (!found) notExistent.push(userEmail);
      else unLinked.push(userEmail);
    }
    const message = 'There were some deleted consultants linked to your file. Those have been removed successfully.';
    this.uiMessaging_$.toastMessage(message, 'IMPORTANT');
    return { unLinked, notExistent };
  }

  deleteGoogleStorageFile(filePath: string, fileName: string, storageBucket: string) {
    return this.files_$
      .deleteGoogleStorageFile(filePath, storageBucket)
      .then(() => {
        console.log('deleteGoogleStorageFile');
        console.log('The file has been deleted from Google Storage.');
      })
      .catch(error => {
        console.error('Error deleting the file from Google Storage.', error);
        return error;
      });
  }

  deleteSingleRow(element, event: { stopPropagation: () => void }) {
    event.stopPropagation();

    if (element.DICOMDIRFileId) {
      this.deleteZippedDiscContentsFile(element);
      return;
    }

    if (!element) return;

    if (element.type !== 'folder') {
      this.selection.selected[0] = element;
      this.openDeleteConfirmDialog();
    } else console.log('Remove folder', element);
  }

  updateDeletedZippedDiscContentsFile(DICOMDIRFileId) {
    return this.files_$.updateDeletedZippedDIscContentsFile(DICOMDIRFileId);
  }

  deleteZippedDiscContentsFile(element) {
    const { filePath, fileId, fileName, storageBucket, DICOMDIRFileId } = element;
    console.log('Delete DICOMDIR', element);

    this.auth_$.showLoaderV2('Deleting the zipped contents file ...', {
      loaderId: 'deleting-zipped-file',
      hasBackdrop: false,
    });

    // 1. Delete the Google Storage file.
    this.deleteGoogleStorageFile(filePath, fileName, storageBucket)
      .then(() => {
        // 2. Update the DICOMDIR file.
        this.files_$
          .removeDownloadLinkFromFile(DICOMDIRFileId)
          .then(() => {
            // 3. Delete the file.
            this.files_$
              .deleteFileByFileId(fileId)
              .then(() => {
                console.log('deleteSingleRow -- getFilesForPatient');
                this.auth_$.updateLoaderMessage('The zipped contents file has been deleted.', 'deleting-zipped-file');
                setTimeout(() => {
                  this.auth_$.hideLoader('deleting-zipped-file');
                  this.getFilesForPatient(this.currentFolder, this.getFilesForPatientOptions());
                  this.auth_$.hideLoader('deleting-zipped-file');
                }, 3000);
              })
              .catch(error => {
                console.error('Error deleting the file.', error);
                this.auth_$.updateLoaderMessage('Error deleting the file.', 'deleting-zipped-file');
                setTimeout(() => this.auth_$.hideLoader('deleting-zipped-file'), 3000);
              });
          })
          .catch(error => {
            console.error('Error deleting the DICOMDIR file.', error);
            this.auth_$.updateLoaderMessage('Error deleting the file.', 'deleting-zipped-file');
            setTimeout(() => this.auth_$.hideLoader('deleting-zipped-file'), 3000);
          });
      })
      .catch(error => {
        console.error('Error deleting the DICOMDIR file.', error);
        this.auth_$.updateLoaderMessage('Error deleting the file.', 'deleting-zipped-file');
        setTimeout(() => this.auth_$.hideLoader('deleting-zipped-file'), 3000);
      });
  }

  deleteFilesFromAlgolia(fileIds: string[]) {
    this.algolia_$
      .deleteManyFromAlgolia(fileIds)
      .then(() => this.uiMessaging_$.toastMessage('Files deleted successfully', 'IMPORTANT'));
  }

  /**
   * Delete multiple files from the files list.
   */
  async deleteFiles(files: any[]) {
    // NOTE: Delete files from Storage.
    this.deleteFilesFromStorage(files.map(f => f.filePath)).then(() => {
      console.log(
        'deleteFiles',
        files.map(f => ({ fileId: f.fileId, filePath: f.filePath })),
      );
    });

    const fileIds = files.map(file => file.fileId);
    let promisesStack = [];

    this.utils_$.getChunks(10, fileIds).forEach(chunk => promisesStack.push(this.files_$.getFilesByFileIdsArr(chunk)));

    const getFileIdsArr = await Promise.all(promisesStack);
    let filesData = [];
    getFileIdsArr.forEach(
      sp => (filesData = [...filesData, ...sp.docs.map(f => ({ id: f.id, fileId: f.data().fileId }))]),
    );

    promisesStack = [];
    filesData.forEach(file => promisesStack.push(this.files_$.deleteFileByDocId(file)));

    Promise.all(promisesStack).then(deletedFiles => {
      this.deleteFilesFromAlgolia(deletedFiles.map(f => f.fileId));
      this.handleDeleteAllFilesNotifications(deletedFiles.map(f => f.fileId));
      this.uiMessaging_$.toastMessage('The file(s) has been deleted.', 'INFO');
      if (!this.userIsConsultant && !this.userIsConsultant) this.updateConsultantsLists();
      console.log('deleteFiles -- getFilesForPatient');
      this.getFilesForPatient(this.currentFolder, this.getFilesForPatientOptions());
    });
  }

  @HostListener('document:click')
  documentClick(): void {
    this.isDisplayContextMenu = false;
  }

  fileSharedValidation(row: {
    sharedUsers: { filter: (arg0: (item: any) => boolean) => { (): any; new (): any; length: any } };
  }) {
    if (!this.activeConsultant || !row.sharedUsers) {
      return false;
    }
    return row.sharedUsers.filter(item => item.email.toLowerCase() === this.activeConsultant.toLowerCase()).length;
  }

  fetchAlgoliaSearchResults(flag: boolean) {
    if (!flag) {
      console.log('fetchAlgoliaSearchResults -- flag is false');
      return;
    }

    const fixMissingFolderName = (): any[] => {
      return this.algolia_$.algoliaSearchResults.map(g => ({
        ...g,
        parentFolderName:
          g.parentFolderName === ''
            ? this.caseFolders.find(f => f.folderId === g.parentFolder).name
            : g.parentFolderName,
      }));
    };

    this.filesTableDataSource.data = fixMissingFolderName();
  }

  filesSelected() {
    return Boolean(this.selection.selected.find(selected => selected?.type !== 'folder'));
  }

  fileSelected() {
    return (
      Boolean(this.selection.selected.find(selected => selected?.type !== 'folder')) &&
      this.selection.selected.length === 1
    );
  }

  focusActiveConsultantsTab() {
    this.selectedTabIndex = 0;
  }

  formatDate(dateString: string | number | Date) {
    return new Date(dateString).toLocaleDateString('en-EN');
  }

  getButtonStyle(name: any) {
    return this.utils_$.getButtonStyle(name);
  }

  getClioAuthorization() {
    this.users_$
      .updateUserByDocId(this.auth_$.userData.value['id'], {
        lastSession: JSON.stringify({ patientDocId: this.casename }),
        client: 'clio',
      })
      .then(() => {
        // NOTE: Is there a way to know this is EU or US?
        const eu =
          this.sessionStorage_$.getAddToClioEU() || this.auth_$.userData.getValue()['clioRegion'] === 'eu' || false;
        this.clio_$.redirectToAuthorize(environment.config.clio.redirectsGroup.clientProfile, eu);
      });
  }

  getPracticePantherAuthorization() {
    this.users_$
      .updateUserByDocId(this.auth_$.userData.value['id'], {
        lastSession: JSON.stringify({ patientDocId: this.casename }),
        client: 'practicepanther',
      })
      .then(() => {
        window.location.href = this.getPracticePantherUrl();
      });
  }

  private getPracticePantherUrl() {
    const practice_panther_client_id = '242f0b50-8ee2-4e0a-ac0e-70d6b91463e9';
    const redirect_uri = encodeURIComponent('https://nuagedx-testing.web.app/practicepantherauth');
    return `https://app.practicepanther.com/oauth/authorize?response_type=code&client_id=${practice_panther_client_id}&redirect_uri=${redirect_uri}`;
  }

  getOtherFolderId(casename: string) {
    return this.files_$.getOtherFolderId(casename);
  }

  getFilesCountByClient(casename: string, roleConsultant?: string, consultantEmail?: string) {
    return this.files_$.getFilesCountByClient(casename, roleConsultant, consultantEmail);
  }

  goBack() {
    window.history.back();
  }

  async goRefresh(ev) {
    ev ? ev.preventDefault() : (ev = {});
    this.firebase_utilities_$.infectedFiles.next([]);
    console.log('goRefresh -- getFilesForPatient');
    this.getFilesForPatient(this.currentFolder, await this.getRefreshOptions());
  }

  async setFolderCountLabels(isConsultant, caseFolders) {
    if (isConsultant) return caseFolders;
    else {
      const options = {
        isConsultant: this.userIsConsultant,
        isClient: this.userIsClient,
        useremail: this.auth_$.userData.value['email'],
      };
      const getLabel = async ({ folderId, name }) => this.files_$.getFolderLabel(folderId, name, options);
      return await Promise.all(
        caseFolders.map(async (folder: { folderId: any; name: any }) => ({ ...folder, label: await getLabel(folder) })),
      );
    }
  }

  async getFolderExtra(folder, roleConsultant, useremail, filesShared) {
    const name = await this.getFolderNameExtra(folder, roleConsultant, useremail, filesShared);
    const hasNotifications = this.checkIfFolderHasNotifications(folder.folderId);
    return {
      // FIXME: No need to got o database to get this number if Consultant.
      creator: this.uid,
      fileDesc: '',
      fileId: this.uploadHelper_$.getRandomString(17),
      fileName: `${folder.name}`,
      hasNotifications,
      ftype: folder.type,
      filePath: '',
      name,
      notes: [],
      predefined: folder.predefined || false,
      parentFolderName: folder.name,
      sharedUsers: [],
      storename: '',
      viewerurl: '',
    };
  }

  async getFolderNameExtra(folder: { name: string }, roleConsultant: any, useremail: any, filesShared: any) {
    return folder.name && folder.name.substr(0, 5) === indexFolderLabel
      ? `${folder.name}`
      : `${folder.name} ${await this.getFolderNameWithCounter(folder, roleConsultant, useremail, filesShared)}`;
  }

  async getFolderNameWithCounter(folder, roleConsultant, useremail, filesShared) {
    const result = await this.firebase_utilities_$.getNumberOfFilesByFolderId(
      folder.folderId,
      roleConsultant,
      useremail,
      filesShared,
    );
    return result;
  }

  private updateFiles(files) {
    // Ask if the files are sorted
    this.files = this.sortedData.length > 0 ? this.sortedData : files;
  }

  async getFilesIndexFolder({ casename, roleConsultant }) {
    const email = this.auth_$.userData.value['email'];
    let files = (await this.files_$.getFilesByCaseName(casename, true)).filter(({ type }) => type !== 'folder');

    if (roleConsultant) {
      files = files
        .filter(({ sharedUsers }) => sharedUsers && sharedUsers.length > 0)
        .filter(({ sharedUsers }) => sharedUsers.some(h => h.email === email));
    }

    // FIXME: If this necessary?
    this.updateFiles(files);

    // NOTE: Build folders array.
    const filesArr = [];

    for (let i = 0; i < files.length; i++) {
      if (files[i].type !== 'folder') {
        const { name, color } = this.getFoldersByParentFolderId(this.caseFolders, files[i].parentFolder);
        files[i]['predefined'] = false;
        files[i]['parentFolderName'] = name;
        files[i]['color'] = color;
      }

      // NOTE: If it is not the OWNER you have to check permissiones before add it to the list.
      if (roleConsultant) {
        files[i]['isShared'] = this.isSharedWithActiveConsultant(files[i].sharedUsers);

        if (files[i]['isShared']) filesArr.push(files[i]);
      } else filesArr.push(files[i]);
    }

    // Sort files by fileName:
    filesArr.sort((a, b) => (a.fileName.toLowerCase() > b.fileName.toLowerCase() ? 1 : -1));
    return filesArr;
  }

  async getFolderExtraX(fileName, ftype, folderId, predefined) {
    return {
      creator: this.uid,
      fileDesc: '',
      fileId: this.uploadHelper_$.getRandomString(17),
      fileName: `${fileName}`,
      ftype: ftype,
      filePath: ``,
      name: `${fileName} ${await this.firebase_utilities_$.getNumberOfFilesByFolderId(folderId)}`,
      parentFolderName: fileName,
      notes: [],
      sharedUsers: [],
      storename: '',
      viewerurl: '',
      predefined: predefined || false,
    };
  }

  async getFilesFolder(paramsObject): Promise<any[]> {
    this.updateFiles(
      await this.files_$.getFilesByParentFolderId(
        paramsObject.folderId,
        paramsObject.casename,
        this.getCreator(),
        this.realRole === UserRoles.consultant,
        this.realRole === UserRoles.consultant ? this.auth_$.userData.value['email'] : '',
      ),
    );

    const filesArr = [];
    for (let index = 0; index < this.files.length; index++) {
      let file = this.files[index];

      file = this.getFileWithNotifications(file);

      if (file.ftype === 'Folder') {
        // Handle folders.
        const folderExtra = await this.getFolderExtraX(file.fileName, file.ftype, file.folderId, file.predefined);
        file = { ...file, ...folderExtra };
        // filesArr.push(file);
      } else {
        // Handle files.
        const { name, color } = this.getFoldersByParentFolderId(this.caseFolders, file.parentFolder);
        file = {
          ...file,
          predefined: false,
          parentFolderName: name,
          color: color,
          hasNotifications: this.checkIfFileHasNotifications(file),
        };

        const isShared = this.isSharedWithActiveConsultant(file.sharedUsers);

        if (this.userIsConsultant && isShared) {
          file = { ...file, isShared };
        }
      }

      filesArr.push(file);

      // // Sort files by fileName:
      // filesArr.sort((a, b) => (a.fileName.toLowerCase() > b.fileName.toLowerCase() ? 1 : -1));
    }

    // Sort files by fileName:
    filesArr.sort((a, b) => (a.fileName.toLowerCase() > b.fileName.toLowerCase() ? 1 : -1));

    return filesArr;
  }

  private getCreator() {
    return this.realRole === UserRoles.client ? this.auth_$.userData.value['uid'] : false;
  }

  private checkIfFileHasNotifications(file) {
    if (file.modifiedNoteNotifications && file.modifiedNoteNotifications.length > 0) return true;
    if (file.newFileNotifications && file.newFileNotifications.length > 0) return true;
    if (file.newNoteNotifications && file.newNoteNotifications.length > 0) return true;
    if (file.sharedFileNotifications && file.sharedFileNotifications.length > 0) return true;

    return false;
  }

  async getFilesArray(folderId: string, roleConsultant, caseFolders) {
    switch (folderId) {
      case HOME_FOLDER_NAME:
        return await this.getFilesHomeFolder({ roleConsultant, caseFolders });
      case INDEX_FOLDER_NAME:
        return await this.getFilesIndexFolder({ roleConsultant, casename: this.casename });
      default:
        return await this.getFilesFolder({ folderId, casename: this.casename });
    }
  }

  /**
   * Get all the files for the current opened Client Profile.
   * @param folderId where the files will be loaded from.
   * @param options globalFolder, folderName
   * @returns a void promise.
   */
  async getFilesForPatient(folderId: string = '', options: any = {}, checkNotifications?: boolean): Promise<any> {
    const { roleConsultant, caseFolders, folderName } = options;
    this.startRefreshing();
    const files = await this.getPatientsFilesArray(folderId, roleConsultant, caseFolders);
    this.updatePage(files, folderId, folderName);
    this.stopRefreshing();
  }

  startRefreshing() {
    this.refreshing = true;
    this.refreshButton = false;
    this.updateFiles([]);
  }

  updatePage(files, folderId, folderName) {
    this.setPaging(files, folderId, folderName);
    this.refreshButton = true;
  }

  stopRefreshing() {
    // this.auth_$.hideLoader();
  }

  showImportFromApps(button?) {
    this.importFromAppsBtn = false;
    const hasClioAccessToken = !!this.auth_$.userData.value['clioAccessToken'] || false;
    const hasPracticePantherAccessToken = !!this.auth_$.userData.value['practicepantherAccessToken'] || false;
    this.dialog_$
      .open(ImportAppsComponent, {
        height: 'auto',
        width: 'auto',
        data: {
          clioAccessToken: hasClioAccessToken,
          practicepantherAccessToken: hasPracticePantherAccessToken,
          button: button || false,
        },
      })
      .afterClosed()
      .toPromise()
      .then(result => {
        switch (result) {
          case 'clio-signin-and-authorize':
            // NOTE: Is there a way to know this is EU or US?
            this.getClioAuthorization();
            break;
          case 'show-clio-matters':
            this.handleClioImport();
            this.importFromAppsBtn = false;
            break;
          case 'practicepanther-signin-and-authorize':
            this.getPracticePantherAuthorization();
            break;
          case 'show-practicepanther-matters':
            this.handlePracticePantherImport();
            this.importFromAppsBtn = false;
            break;
          case 'remove-clio-token':
            this.ngOnInit();
            break;
          default:
            console.log('No App Selected');
            this.importFromAppsBtn = true;
            break;
        }
      });
  }

  getComponent(client) {
    let component;
    switch (client) {
      case 'clio':
        component = ClioListMattersComponent;
        break;
      case 'practicepanther':
        component = PracticePantherListMattersComponent;
        break;
      default:
        component = null;
        break;
    }
    return component;
  }

  showMattersList(mattersData, client?) {
    let component = this.getComponent(client);

    this.dialog_$
      .open(component, {
        height: 'auto',
        width: 'auto',
        data: {
          matters: mattersData,
          currentCase: this.casename,
          currentFolder: this.currentFolder,
          clientName: this.patientName,
        },
      })
      .afterClosed()
      .subscribe({
        next: val => {
          this.importFromAppsBtn = true;
          if (val === 'IMPORTED') {
            this.uiMessaging_$.toastMessage('The import finished successfully', 'INFO');
            console.log('showMattersList --  getFilesForPatient');
            this.getFilesForPatient('', {
              caseFolders: this.caseFolders,
              roleConsultant: this.userIsConsultant,
              roleClient: this.userIsClient,
            });
          }
        },
        error: err => console.error(err),
      });
  }

  /**
   * Get the folder name of a given folderId.
   * @param caseFolders is the array having all the folders (name,id) of the given casename.
   * @param parentFolderId to match with the caseFolder items.
   * @returns the name of the folder or empty string.
   */
  getFolderName(caseFolders, parentFolderId): string {
    if (['all', ''].includes(parentFolderId)) return '';
    else {
      const filtered = caseFolders.find(folder => folder.folderId === parentFolderId);
      return filtered ? filtered.name : '';
    }
  }

  getFoldersByParentFolderId(caseFolders: any[], parentFolderId: string): FolderElement {
    const filtered = caseFolders.filter(({ folderId }) => folderId === parentFolderId)[0];
    if (!filtered) console.log('getFoldersByParentFolderId filtered empty: ', parentFolderId);
    const filteredFolders = filtered || {
      name: 'top',
      id: 'top',
      color: 'pink',
    };
    return filteredFolders;
  }

  async getMissingSharedFiles(file) {
    if (!file.sharedUsers) {
      return;
    }
    const sharedUserEmails = file.sharedUsers.map(u => u.email.toLowerCase());
    console.log('sharedUserEmails: ', sharedUserEmails);
    const activeConsultantsEmails = this.activeConsultants.map(ac => ac.email.toLowerCase());
    const notFound = this.findIn(sharedUserEmails, activeConsultantsEmails);
    console.log('notFound: ', notFound);
    if (!notFound.length) {
      return;
    }

    const { unLinked, notExistent } = await this.clearNonExistentUsers(notFound);
    this.repairUnLinkedUsers(unLinked, notFound, file);
    this.repairNonExistent(notExistent, file);
    this.ngOnInit();
  }

  findIn(arr1, arr2) {
    const notFound = [];
    arr1.forEach(e => {
      if (!arr2.includes(e)) {
        notFound.push(e);
      }
    });
    return notFound;
  }

  async getFolderIdByFolderName(folderName: string) {
    console.log('folderName: ', folderName);
    if (!this.caseFolders || this.caseFolders.length === 0) return;

    const folder = this.caseFolders.find(f => f.name === folderName);

    if (!folder) {
      this.auth_$.showLoader(`Creating missing ${folderName} folder...`);
      await this.firebase_utilities_$.createFolder(this.createFolderObject(folderName));
      this.auth_$.hideLoader();
      await this.refreshCaseFolders();
      return await this.getFolderIdByFolderName(folderName);
    }

    return Promise.resolve(folder.folderId);
  }

  async getFolderId(folderName) {
    return this.getFolderIdByFolderName(folderName);
  }

  async deleteStores(filesIDs) {
    this.clientDataStores = await this.uploadHelper_$.getDataStoresByClient(this.casename);
    if (!this.clientDataStores) return;

    const dicomStores = this.clientDataStores.map(urlname => {
      this.utils_$.viewerurlToDicomStore(
        `${environment.config.ohifViewerURL}${urlname.name}`,
        viewerUrlParts.dicomStore,
      );
    });
    this.gapiOperations_$
      .deleteStoresByCaseName(this.casename, dicomStores)
      .then(result => {
        console.log(`DICOM Store deleted successfully`, result);
        return 200;
      })
      .catch(reason => {
        // TODO: #187 There is an error when try to delete all files from a client profile.
        console.error(`Error deleting : ${reason}`);
        console.log('Reason', reason);
        return 0;
      });
  }

  getStoreName(fileId): Promise<any> {
    return this.files_$.getFileByFileId(fileId).then(res => console.log('file: ', res.docs[0].data()));
  }

  getStoresFromFileIDsList(filesIDsToDelete) {
    const dataStores = [];
    filesIDsToDelete.forEach(file => dataStores.push(this.getStoreName(file.fileId)));
  }

  async handleFolderClick(event, folder) {
    event.stopPropagation();
    event.preventDefault();

    this.auth_$.showLoader(`Loading folder '${folder.name}' contents...`, 'loading-folder-contents');
    let index;

    if (folder.predefined === true && folder.folderId !== 'all') index = 0;
    else if (folder.folderId === 'all') index = 1;
    else index = 3;

    this.folderColor = localFolderColors[index];

    // before browse folder contents, check if the folder has any shared files that are not shared with the active consultant.
    await this.browseFolderContents(folder.folderId, folder.name);
    this.updateConsultantsLists();
    // this.auth_$.hideLoader(); // FIXME: Avoid hide the DOWNLOAD DISC NOTIFICATION.
    this.auth_$.hideLoader('loading-folder-contents');
  }

  async handleFolderClickV2(event, element) {
    const { predefined, folderId, name } = element;
    this.auth_$.showLoader(`Loading folder '${name}' contents...`, 'loading-folder-contents');
    let index;

    if (predefined === true && folderId !== 'all') index = 0;
    else if (folderId === 'all') index = 1;
    else index = 3;

    this.folderColor = localFolderColors[index];
    await this.browseFolderContentsV2(folderId, name);
    this.auth_$.hideLoader();
  }

  handleFileClick(event, element) {
    event.stopPropagation();

    this.handleDeleteNewFileAndSharedFileNotifications(element);
    this.getRidOfNotesNotifications(element, [NotificationTypes.newfile, NotificationTypes.sharedfile]);

    if (element.ftype === UploadTypes.DICOMDisk) this.handleStudyClick(element, event);
    else this.handlSimpleFileClick(element);
  }

  async handleStudyClick(element, event) {
    // Log the study browsing action.
    this.log_$.storeLog(
      this.auth_$.userData.getValue(),
      LogLevelsDictionary.info,
      { message: 'A study has been browsed.' },
      'BrowseStudy',
      this.casename,
    );

    event.preventDefault();

    this.auth_$.showLoader('Opening the study viewer...');

    if (environment.config.viewerIndex === 0) {
      await this.preStudyViewAction(); // FIXME: WIP
    }

    this.auth_$.hideLoader();

    window.open(this.getViewerUrl(element.viewerurl), '_blank');

    // Remove notifications related to the file.
    this.handleDeleteNewFileAndSharedFileNotifications(element);
  }

  private getViewerUrl(viewerurl: string): string {
    return `${environment.config.ohifViewerURL}${viewerurl.substr(viewerurl.indexOf('/projects'))}`;
  }

  private async preStudyViewAction() {
    const randomState = this.uploadHelper_$.getRandomString(20);
    const email = this.auth_$.userData.getValue()['email'];
    const result = (await this.updateUserStudyViewAction(randomState, email))['data'];
    const customToken = result['customToken'];
    const cookieValue = `${randomState}#${email}#${customToken}`;
    const cookieName = 'studyOpenedFromNuageDxApp';

    this.cookies_$.setCookie(cookieName, cookieValue, 1);
    console.log('The cookie was set successfully');
  }

  private updateUserStudyViewAction(randomState, email) {
    return firebase.functions().httpsCallable('user-updateUserStudyViewAction')({ randomState, email });
  }

  async handleRegularFileClick(filePath: string, fileName: string) {
    this.auth_$.showLoader('Opening the document viewer...', 'loading-document-viewer');
    await this.previewDocument(filePath, fileName);
    this.auth_$.hideLoader();
  }

  private getFileNameExtension(fileName: string): string {
    return this.utils_$.getFileExtension(fileName);
  }

  async handlSimpleFileClick(element) {
    const { fileId, fileName } = element;
    const fileExtension = this.getFileNameExtension(fileName);

    // Show the loader.
    this.auth_$.showLoader('Getting the file path...');

    // Log the file browsing action.
    this.log_$.storeLog(
      this.auth_$.userData.getValue(),
      LogLevelsDictionary.info,
      { message: 'A file has been browsed.', filename: fileName },
      'BrowseFile',
      this.casename,
    );

    const filePath = (await this.files_$.getFileByFileId(fileId)).docs[0].data().filePath;

    if (notPlayableVideoExtensions.includes(fileExtension)) this.handleVOBFileClick(filePath);
    else if (['zip', 'rar', '7zip'].includes(fileExtension))
      await this.handleZIPFileClick(filePath, element.storageBucket || '');
    else await this.handleSimpleFileClick(filePath, fileName);
  }

  private handleZIPFileClick(filePath: string, bucket?: string) {
    return this.handleDownloadFile(filePath, bucket);
  }

  private async getZippedFileFromDICOMDIRFileId(fileId) {
    const fileData = await this.files_$.getZippedFileByDDICOMDIRFileId(fileId);
    console.log('fileData: ', fileData);
    return fileData;
  }

  private async handleDerivedZIPFileClick(DICOMDIRFile) {
    const zippedFile = await this.getZippedFileFromDICOMDIRFileId(DICOMDIRFile.fileId).catch(err => {
      console.error(err);
      return null;
    });
    return this.handleDownloadFile(zippedFile.filePath, 'nuagedx-dev.appspot.com');
    // return this.handleDownloadFile(zippedFile.filePath, 'testing-bucket-001-spideep');
  }

  private async handleSimpleFileClick(filePath: string, fileName: string) {
    // Validate file metadata.
    if (!(await this.validateFileMeta(filePath))) console.log('The file metadata is not valid.');
    else this.handleRegularFileClick(filePath, fileName);
  }

  handleVOBFileClick(filePath) {
    const dialogRef = this.dialog_$.open(VOBInfoWindowComponent, { height: 'auto', width: 'auto' });
    dialogRef
      .afterClosed()
      .toPromise()
      .then(async res => {
        if (res === 'download') await this.handleDownloadFile(filePath, '');
        else if (res === 'getConvertAndExport') this.handleConvertAndExport(firebase.storage().ref(), filePath);
      });
  }

  private handleDownloadFile(filePath, storageBucket) {
    this.auth_$.showLoader('Downloading the file...');
    const afterGetSignedURL = result => {
      console.log('result :', result);
      this.auth_$.hideLoader();
      window.open(result.data.url, '_blank');
    };
    return firebase
      .functions()
      .httpsCallable('firebaseUtilities-fileGetSignedURLV2')({ filePath, storageBucket })
      .then(result => afterGetSignedURL(result))
      .catch(err => this.handleError(err));
  }

  private handleConvertAndExport(pathReference, filePath) {
    pathReference
      .child(filePath)
      .getDownloadURL()
      .then(targetURL => {
        firebase
          .functions()
          .httpsCallable('cloudconvert-getConvertAndExport')({ url: targetURL })
          .then(result => console.log('result: ', result));
      });
  }

  handleSelect(value) {
    const type = this.sortTypes[value];
    this.setSort(type.value, type.direction);
  }

  handleTabsClick(event: MatTabChangeEvent) {
    if (event.index === 0 && this.available_consultants) this.available_consultants.selectedOptionsClear();
    else if (event.index === 1 && this.active_consultants) {
      this.activeConsultant = null;
      this.active_consultants.selectedOptionsClear();
    }
  }

  async handleDeleteAllFiles() {
    /**
     * NOTE: The reason of these duplicated calls using caseName and LegalCaseId is to prevent
     * those records with these values.This is only for old records, new ones should use
     * caseName only.
     */
    const filesToDelete = this.selection.selected.map(({ fileId, sharedUsers }) => ({ fileId, sharedUsers }));
    const filesIDs = filesToDelete.map(file => file.fileId);
    const promises = [];
    promises.push(this.firebase_utilities_$.deleteFilesByIds(filesIDs));

    // NOTE: Remove shared users references to the files.
    filesToDelete.forEach(({ sharedUsers, fileId }) => {
      if (sharedUsers) {
        console.log('file.sharedUsers: ', sharedUsers);
        const shareUserEmail = sharedUsers.map(user => user.email.toLowerCase());
        promises.push(this.firebase_utilities_$.removeFilesByIdsFromUser(fileId, shareUserEmail));
      }
    });

    // Delete all notifications related to the files.
    this.handleDeleteAllFilesNotifications(filesIDs);

    return Promise.all(promises);
  }

  async handleDeleteAllStores() {
    this.clientDataStores = await this.uploadHelper_$.getDataStoresByClient(this.casename);
    if (!this.clientDataStores) {
      return;
    }
    const dicomStores = this.clientDataStores.map(urlname =>
      this.utils_$.viewerurlToDicomStore(
        `${environment.config.ohifViewerURL}${urlname.name}`,
        viewerUrlParts.dicomStore,
      ),
    );
    return this.gapiOperations_$
      .deleteStoresByCaseName(this.casename, dicomStores)
      .then(result => {
        console.log(`DICOM Store deleted successfully`, result);
        return 200;
      })
      .catch(reason => {
        // TODO: #187 There is an error when try to delete all files from a client profile.
        console.error(`Error deleting : ${reason}`);
        console.log('Reason', reason);
        return 0;
      });
  }

  handleCheckboxClick(ev: { preventDefault: () => void }) {
    ev.preventDefault();
  }

  handleClioImport() {
    if (this.checkClioAuthToken()) this.listClioMatters();
    else this.getClioAuthorization(); // NOTE: Is there a way to know this is EU or US?
  }

  handlePracticePantherImport() {
    // Check if there is a token
    if (this.checkPracticePantherAuthToken()) this.listPracticePantherMatters();
    // Open matters list.
    else this.getPracticePantherAuthorization();
  }

  /**
   * handleRowClick happens when user clicks on a single row of the DataTable.
   */
  handleRowClick(element, event, options?) {
    this.cleanHighlighted();
    this.showSharedConsultantsByFile({});

    const { currentFolder } = options;
    if (currentFolder === '') return;
    event.stopPropagation();
    this.selection.toggle(element);
    this.auth_$.clientProfileSelectedFiles = this.selection.selected;

    if (this.selection.selected.length > 0) {
      this.activeConsultants = this.addSharedFlags(this.activeConsultants, this.selection.selected[0].sharedUsers);
      this.availableConsultants = this.addSharedFlags(
        this.availableConsultants,
        this.selection.selected[0].sharedUsers,
      );

      this.viewNotesButton =
        this.selection.selected.length === 1
          ? this.selection.selected[0].notes && this.selection.selected[0].notes.length > 0
          : false;
    } else if (this.selection.selected.length === 0) {
      this.viewNotesButton = false;
      this.activeConsultants = this.clearSharedFlags(this.activeConsultants);
      this.availableConsultants = this.clearSharedFlags(this.availableConsultants);
    }
  }

  /**
   * This is called from the template when an Active Consultant option is clicked.
   */
  handleActiveConsultantClick(event) {
    this.selectionRecord = this.selection.selected;

    if (event === '') this.noEvent();

    this.cleanHighlighted();
    this.activeConsultant = event.email || null;
    this.updateDataSharedStatus();
    this.setSort('isShared', 'desc');
    this.selectionRecord.forEach(record => this.selection.toggle(record));
  }

  handleBreadcrumbClick(event) {
    const folderName = this.getFolderName(this.caseFolders, event);
    this.showLoadingMessage(folderName);
    this.loadPatientFiles(event, folderName).then(() => {
      this.resetSelection();
      this.auth_$.hideLoader('loading-folder-contents');
    });
  }

  showLoadingMessage(folderName: string) {
    const displayFolderName = folderName === '' ? 'Home' : folderName;
    const loadingMessage = `Loading ${displayFolderName} folder contents...`;
    this.auth_$.showLoader(loadingMessage, 'loading-folder-contents');
  }

  private resetSelection() {
    this.activeConsultant = null;
    this.selection.clear();
    // FIXME: Avoid to hide the GENERATE DISC NOTIFICATION.
    this.auth_$.hideLoader('loading-folder-contents');
  }

  private loadPatientFiles(event, folderName) {
    return this.getFilesForPatient(event, {
      folderName: folderName,
      roleConsultant: this.userIsConsultant,
      roleClient: this.userIsClient,
      user: this.user,
      caseFolders: this.caseFolders,
    });
  }

  /**
   * Check if the @param fileSharedUsers contains the activeConsultant one.
   */
  isSharedWithActiveConsultant(fileSharedUsers: any[]): boolean {
    // FIXME: Temporal solution while this.activeConsultant keeps null.
    if (this.realRole === UserRoles.consultant) {
      this.activeConsultant = this.auth_$.userData.getValue()['userEmail'];
    }

    if (typeof fileSharedUsers === 'undefined') {
      return false;
    }

    if (this.activeConsultant === null) {
      return fileSharedUsers && Boolean(fileSharedUsers.length);
    }

    for (let index = 0; index < fileSharedUsers.length; index++) {
      const user = fileSharedUsers[index];
      if (user.email === this.activeConsultant) {
        // FIXME: this.activeConsultant should be populated on signin
        return true;
      }
    }
    return false;
  }

  isAllSelected() {
    return this.selection.selected.length === this.files.length;
  }

  async listClioMatters() {
    // FIXME: Make this call part of clio service.
    this.auth_$.showLoader(`Loading matters for ${this.auth_$.userData.getValue()['userEmail']}`);
    firebase
      .auth()
      .currentUser.getIdToken()
      .then(token => this.handleClioSecuredGetMatters(token));
  }

  private async handleClioSecuredGetMatters(token) {
    const userdocid = await this.firebase_utilities_$.getUserDocId(this.auth_$.uid);
    const url = `${environment.constants.cloudfunctionsURL}clio-securedGetMatters`;
    const uid = this.auth_$.uid;

    this.http
      .post(
        url,
        { userdocid, uid },
        {
          headers: new HttpHeaders().set('Authorization', 'Bearer ' + token),
        },
      )
      .toPromise()
      .then(res => {
        if (res['data'].length) {
          this.auth_$.hideLoader();
          this.showMattersList(res['data'], 'clio');
        }
      })
      .catch(async err => {
        await this.clioSecuredGetMattersError(err);
      });
  }

  async clioSecuredGetMattersError(err) {
    this.auth_$.hideLoader();
    const errormsg = `Error getting matters from Clio, please try again.`;
    const label = 'ERROR';
    this.uiMessaging_$.toastMessage(errormsg, label);

    if (err.error.status === 401) {
      this.importFromAppsBtn = true;

      const result = await this.deleteClioAccessToken().catch(error => {
        console.log(error);
        const message = 'Error deleting Clio token. Please contact the system administrator.';
        this.uiMessaging_$.toastMessage(message, label);
        return false;
      });

      if (result) {
        await this.auth_$.userReady(this.auth_$.userData.getValue(), 'listClioMatters');
      }
    }
    return err;
  }

  deleteClioAccessToken() {
    const url = environment.constants.cloudfunctionsURL + 'clio-deleteClioAccessToken';
    return this.http.post(url, { email: this.auth_$.userData.getValue()['userEmail'] }).toPromise();
  }

  async listPracticePantherMatters() {
    const url = environment.constants.cloudfunctionsURL + 'practicepanther-getMatters';
    const userdocid = await this.firebase_utilities_$.getUserDocId(this.auth_$.uid);
    this.auth_$.showLoader('List Matters...');
    this.http
      .post(url, { userdocid })
      .toPromise()
      .then(res => {
        if (res['length']) {
          this.auth_$.hideLoader();
          this.showMattersList(res, 'practicepanther');
        }
      });
  }

  /**
   * Check/UnCheck all rows in the dataTable.
   */
  masterToggle() {
    const event = document.createEvent('HTMLEvents');
    // tslint:disable-next-line: deprecation
    event.initEvent('click', true, false);
    this.isAllSelected()
      ? this.files
          .filter(row => this.checkIfSelected(row))
          .forEach(row => this.handleRowClick(row, event, { currentFolder: this.currentFolder }))
      : this.files
          .filter(row => !this.checkIfSelected(row))
          .forEach(row => this.handleRowClick(row, event, { currentFolder: this.currentFolder }));
  }

  async moveSelectedTo(folderId: string) {
    return this.firebase_utilities_$.moveFilesTo(this.selection.selected, folderId);
  }

  checkDuplicatedFolder(folderName: string, currentFolder?: string): boolean {
    return Boolean(
      this.caseFolders.find(folder => folder.name.toLowerCase().trim() === folderName.toLowerCase().trim()),
    );
  }

  createFolder(currentFolder, options) {
    this.dialog_$
      .open(NewFolderComponent, {})
      .afterClosed()
      .subscribe(async newFolderName => {
        if (!newFolderName) {
          return;
        }

        if (this.checkDuplicatedFolder(newFolderName, currentFolder)) {
          const message = `Folder name ${newFolderName} is already taken`;
          const label = '';
          this.uiMessaging_$.toastMessage(message, label);
          return;
        }

        const folderObject = {
          children: '',
          name: newFolderName.trim(),
          color: '#c5f0f6',
          parentFolder: '',
          type: 'folder',
          belongsTo: this.casename,
          predefined: false,
          forDelete: false,
          folderId: this.uploadHelper_$.getRandomString(20),
        };

        this.auth_$.showLoader('Creating folder...');
        await this.firebase_utilities_$.createFolder(folderObject);
        this.caseFolders.push(folderObject);
        console.log('createFolder -- getFilesForPatient');

        this.getFilesForPatient(currentFolder, {
          ...options,
          caseFolders: this.caseFolders,
          roleConsultant: this.userIsConsultant,
          roleClient: this.userIsClient,
        }).then(() => this.auth_$.hideLoader());
      });
  }

  async addNote(noteFile: NoteFile, { attachments, name, description, useremail, noteId, visibility }: NoteObject) {
    const attachmentLists = await this.getAttachmentLists(attachments);
    const newAttachments = attachmentLists ? [].concat(attachmentLists) : [];
    noteFile.notes = noteFile.notes || [];
    const noteObject = this.generateNoteObject(
      name,
      description,
      useremail,
      newAttachments,
      noteFile,
      noteId,
      visibility,
    );

    noteFile.notes.push(noteObject);

    await this.updateNote(noteFile.id, noteFile.notes);

    return {
      docId: noteFile.id,
      id: noteFile.fileId,
      type: noteFile.type,
      parentFolder: noteFile.parentFolder,
      noteId: noteObject.noteId,
    };
  }

  updateNote(noteFileId, noteFileNotes) {
    return this.files_$
      .updateFileByDocId(noteFileId, { notes: noteFileNotes })
      .then(() => {
        this.uiMessaging_$.toastMessage('Notes Added Successfully', null);
        return 'Note Added Successfully';
      })
      .catch(error => {
        this.uiMessaging_$.toastMessage(error.message, null);
        return error.message;
      });
  }

  generateNoteObject(name, description, useremail, newAttachments, noteFile, noteId, visibility) {
    return {
      title: name,
      note: description,
      createdBy: useremail,
      attachments: newAttachments.concat(noteFile.attachments || []),
      noteId: noteId || this.generateNoteId(),
      visibility: visibility || { consultants: true, clients: true },
      datetime: new Date().toISOString(),
      fileId: noteFile.fileId,
      authorName: this.auth_$.userData.getValue()['userName'],
    };
  }

  onRightClick(event: MouseEvent, item) {
    event.preventDefault();
    this.menuTopLeftPosition.x = event.clientX + 'px';
    this.menuTopLeftPosition.y = event.clientY + 'px';
    this.matMenuTrigger.menuData = { item: item };
    this.matMenuTrigger.openMenu();
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onlySharedFolders(folder) {
    const isSharedWithConsultant = (sharedUsers: any[], consultantEmail: string) => {
      if (typeof sharedUsers === 'undefined' || !sharedUsers.length) {
        return;
      }
      return sharedUsers.includes(consultantEmail.toLowerCase());
    };
    return isSharedWithConsultant(folder.data().sharedUsers, this.user.email.toLowerCase());
  }

  openDialog(options?) {
    const buildDialogConfig = options => {
      const action_params = {
        files: '',
        type: '',
        desc: '',
        caseName: this.casename,
        creator: this.uid,
      };
      const currentFolder = { folderId: this.currentFolder, folderName: this.currentFolderName };
      const dialogConfigData = {
        id: 1,
        section: options.type ? false : options.section || null,
        clioMatterId: (options && options.clioMatterId) || null,
        title: options && options.section === 'upload_dicom_disk' ? 'Upload Medical Images' : 'Upload Files and Disks',
        currentFolder: currentFolder,
        uploadTypes: this.uploadTypes,
        ftime: this.ftime,
        fdate: this.fdate,
        basicfile: this.files,
        description: this.fdesc,
        type: this.ftype,
        clientName: this.patientName,
        action_params: action_params,
        ownerPlanCode: this.ownerPlanCode,
        ownerID: this.ownerID,
      };
      const dialogConfig = new MatDialogConfig();
      this.dialog_$.dialog.openDialogs.pop();

      dialogConfig.width = '700px';
      dialogConfig.disableClose = false;
      dialogConfig.autoFocus = true;
      dialogConfig.maxHeight = '70vw';
      dialogConfig.data = dialogConfigData;

      return dialogConfig;
    };

    this.dialog_$.dialog.openDialogs.pop();
    const dialogRef = this.dialog.open(UploadDialogComponent, buildDialogConfig(options));
    // const dialogRef = this.dialog_$.open(UploadDialogComponent, buildDialogConfig(options));
    const subs = dialogRef.componentInstance.finish.subscribe(async data => {
      if (data) {
        if (data.action === 'getfiles') {
          this.caseFolders = await this.firebase_utilities_$.getFolders(this.casename);
          this.loading = true;
          this.loadingMessage = 'This list is getting updated, please wait.';
          console.log('openDialog -- getFilesForPatient');
          await this.getFilesForPatient(this.currentFolder, {
            folderName: this.currentFolderName,
            caseFolders: this.caseFolders,
            roleConsultant: this.userIsConsultant,
          });
          this.loading = false;
        }
      } else return false;
    });

    dialogRef
      .keydownEvents()
      .toPromise()
      .then(e => {
        if (!e) return;
        if (e.key === 'Escape') {
          e.preventDefault();
          dialogRef.close();
        }
      });

    dialogRef
      .afterClosed()
      .toPromise()
      .then(result => {
        console.log('The dialog was closed', result);
        subs.unsubscribe();
      });
  }

  openDeleteConfirmDialog() {
    const dialogConfig = new MatDialogConfig();
    const plural = this.selection.selected.length > 1 ? `s` : ``;
    const condition = this.selection.selected.length === this.filesTableDataSource.data.length;

    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    dialogConfig.data = this.getDeleteConfirmDialogConfigData(plural, condition);

    this.dialog_$
      .open(ConfirmationDialogComponent, dialogConfig)
      .afterClosed()
      .toPromise()
      .then(async result => {
        if (!result) return;
        if (!result.deleteAllFiles) this.deleteFiles(this.selection.selected);
        else if (result.selectedFiles === null) {
          const deletionResult = await Promise.all([this.handleDeleteAllStores(), this.handleDeleteAllFiles()]);
          console.log('deletionResult :', deletionResult);
        } else console.log('log val', result);

        console.log('openDeleteConfirmDialog -- getFilesForPatient');

        this.getFilesForPatient(this.currentFolder, {
          folderName: this.currentFolderName,
          caseFolders: this.caseFolders,
          roleConsultant: this.userIsConsultant,
          roleClient: this.userIsClient,
        });

        this.selection.clear();
      });
  }

  openInfoDialog() {
    this.dialog_$
      .open(InfoWindowComponent, { height: 'auto', width: 'auto' })
      .afterClosed()
      .toPromise()
      .then(() => this.openDialog());
  }

  open() {
    this.picker.open();
  }

  openAddNoteDialog() {
    const selectedFile = this.selection.selected[0];
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '400px';
    dialogConfig.data = {
      title: 'Add Comments and/or Attachments',
      selected_file: selectedFile,
      useremail: this.user.email,
      userRole: this.realRole,
      name: '',
      description: '',
      visibility: { consultants: true, clients: true },
    };

    this.dialog_$
      .open(NoteDialogComponent, dialogConfig)
      .afterClosed()
      .toPromise()
      .then(result => {
        if (result?.type === 'notesave') this.noteDialogComponentCloseHandler(result);
      });
  }

  private noteDialogComponentCloseHandler(result) {
    // this.addNote(result.selected_file, result.data).then(({ docId, id, type, parentFolder, noteId }) =>
    //   this.afterAddNote({ fileDocId: docId, id, type, parentFolder, noteId }),
    // );
    this.fileCommentsAttachments_$
      .addCommentsAndAttachments(
        result.selected_file,
        result.data,
        this.patientName,
        this.auth_$.userData.getValue()['userName'],
      )
      .then(({ docId, id, type, parentFolder, noteId }) =>
        this.afterAddNote({ fileDocId: docId, id, type, parentFolder, noteId }),
      );
  }

  /**
   * Creates a id string with 10 random characters.
   *
   * @private
   * @return {*}
   * @memberof ClientProfileComponent
   */
  private generateNoteId() {
    return this.utils_$.getRandomString_(10);
  }

  async openCommentsAndAttachments(fileClicked) {
    let width: string, height: string, maxWidth: string, maxHeight: string;
    if (fileClicked.ftype === 'DICOM Disk (medical images)') {
      width = '95vw';
      height = '95vh';
      maxWidth = '95vw';
      maxHeight = '95vh';
    } else {
      width = '85vw';
      height = '80vh';
      maxWidth = '85vw';
      maxHeight = '95vh';
    }
    const dialogConfig = this.createDialogConfig({
      width,
      height,
      maxWidth,
      maxHeight,
      autoFocus: true,
      data: await this.populateAllNotesDialogData(fileClicked),
      disableClose: false,
    });

    const dialog = this.dialog_$.open(AllNotesDialogComponent, dialogConfig);
    dialog
      .afterClosed()
      .toPromise()
      .then(val => this.allNotesCloseHandler({ fileId: dialogConfig.data.fileid }));
  }

  /**
   * Show all notes of a selected file.
   *
   * @memberof ClientProfileComponent
   */
  async openAllNotes(fileClicked) {
    const dialogConfig = this.createDialogConfig({
      width: '85vw',
      height: '80vh',
      maxWidth: '85vw',
      maxHeight: '95vh',
      autoFocus: true,
      data: await this.populateAllNotesDialogData(fileClicked),
      disableClose: false,
    });

    this.dialog_$
      .open(AllNotesDialogComponent, dialogConfig)
      .afterClosed()
      .toPromise()
      .then(val => this.allNotesCloseHandler({ fileId: dialogConfig.data.fileid }));
  }

  openFileSearch() {
    const dialogConfig = new MatDialogConfig();
    this.dialog_$.dialog.openDialogs.pop();
    dialogConfig.width = '400px';
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.maxHeight = '80vw';
    dialogConfig.data = this.getSeachFilesComponentData();

    const dialogRef = this.dialog.open(SearchFilesComponent, dialogConfig);
    // const dialogRef = this.dialog_$.open(SearchFilesComponent, dialogConfig);
    const subs = dialogRef.componentInstance.updateResultsEvent.subscribe(() => this.fetchAlgoliaSearchResults(true));

    dialogRef
      .keydownEvents()
      .toPromise()
      .then(e => {
        if (!e) return false;
        if (e.key === 'Escape') {
          e.preventDefault();
          dialogRef.close();
        }
      });

    dialogRef
      .afterClosed()
      .toPromise()
      .then(result => subs.unsubscribe());
  }

  renameElement(element, event) {
    event.stopPropagation();
    if (element) this.selection.selected[0] = element;

    const selectedFile = this.selection.selected[0];
    const dialogConfig = new MatDialogConfig();

    let dialogData = {};

    if (element.type === 'folder') {
      dialogData = {
        title: 'Edit folder',
        name: selectedFile.fileName,
        type: element.type,
        label: 'Folder name',
      };
    } else {
      const newDateParts = selectedFile.fdate.split('/');

      dialogData = {
        title: 'Edit file',
        label: 'File name',
        message: `Please add a note here:`,
        confirm_action: this.addNote,
        selected_file: selectedFile,
        useremail: this.user.email,
        name: selectedFile.fileName,
        desc: selectedFile.fileDesc,
        date: new Date(newDateParts[2], parseInt(newDateParts[0], 10) - 1, newDateParts[1]),
        description: '',
      };
    }

    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    dialogConfig.data = dialogData;

    const dialogRef = this.dialog_$.open(EditFileDialogComponent, dialogConfig);

    dialogRef
      .afterClosed()
      .toPromise()
      .then(data => (data ? this.updateFile(data, selectedFile) : null));
  }

  /**
   * Update linked users with the selected file if they are unlinked. "Unlinked"
   * means the file is shared with a consultant but the consultant has no
   * registry of this.
   * @param unLinked is the array of email addresses unlinked with the file.
   * @param file selected.
   */
  async repairUnLinkedUsers(unLinked: string[], notFound, file): Promise<void> {
    if (!unLinked.length) return;
    return this.users_$.getUsersByEmailsArray(unLinked).then(sp => {
      if (!sp.docs.length) {
        console.log('Missing consultant', notFound);
        return;
      }
      sp.docs.forEach(doc =>
        this.users_$.updateUserByDocId(doc.id, {
          filesSharedWith: firebase.firestore.FieldValue.arrayUnion({
            fileId: file.fileId,
            patientCaseName: this.casename,
            ownerID: this.ownerID,
          }),
        }),
      );
      this.uiMessaging_$.toastMessage(
        'There were some consultants unlinked with your shared file, those have been repaired',
        'IMPORTANT',
      );
    });
  }

  /**
   * Removes the link of the selected file with users than are deleted
   * or removed from firestore.
   * @param notExistent the array of emails.
   * @param file to be requested.
   */
  async repairNonExistent(notExistent, file): Promise<void> {
    if (!notExistent.length) return;
    const sharedUsers = (await this.files_$.getFileByFileId(file.fileId)).docs[0].data()['sharedUsers'];

    for (let index = 0; index < notExistent.length; index++) {
      const uemail = notExistent[index].toLowerCase();
      const fileDBId = (await this.files_$.getFileByFileId(file.fileId)).docs[0].id;
      this.files_$.updateFileByDocId(fileDBId, {
        sharedUsers: sharedUsers.filter(user => user.email.toLowerCase() !== uemail.toLowerCase()),
      });
    }
  }

  setPageSize(objectsCount: number = 0) {
    for (let index = 0; index < this.pageSizes.length; index++) {
      const element = this.pageSizes[index];
      if (element >= objectsCount) {
        this.pageSize = element;
        break;
      }
    }
    if (objectsCount > this.pageSizes[this.pageSizes.length]) this.pageSize = this.pageSizes[this.pageSizes.length];
    this.pagesLength = Math.ceil(objectsCount / this.pageSize);
  }

  async setPermissions() {
    this.loadingPermissions = true;
    const prefix = 'permissions_';
    let permissionsArr;
    if (
      this.auth_$.getPermissions('clientprofile_permissions') &&
      JSON.parse(this.auth_$.getPermissions('clientprofile_permissions')).length === permissionsNeeded.length
    ) {
      permissionsArr = JSON.parse(this.auth_$.getPermissions('clientprofile_permissions'));
    } else {
      const permissions = await this.validateRoleMulti(permissionsNeeded);
      permissionsArr = permissions.map(item => ({
        name: prefix + item.object,
        value: item.access,
      }));
      this.auth_$.setPermissions(permissionsArr);
    }
    permissionsArr.forEach(item => (this[item.name] = item.value));
    this.loadingPermissions = false;
  }

  async shareSingleFile(row, event) {
    event.stopPropagation();

    this.sendFileSharingEmailNotification({
      email: this.activeConsultant,
      casename: this.casename,
      files: row.fileName,
    });

    this.firebase_utilities_$.shareFile(row, this.activeConsultant, this.casename).then(() => {
      this.afterShareFile(row);
    });
  }

  async setFileForDelete(file) {
    const fileId = (await this.files_$.getFileByFileId(file.fileId)).docs[0].id;
    this.files_$.updateFileByDocId(fileId, { forDelete: true });
  }

  async selectTarget(type) {
    this.caseFolders = await this.firebase_utilities_$.getFolders(this.casename);
    const treeData = this.caseFolders
      .map(({ name, color, folderId }) => ({ name, id: folderId, color, children: [] }))
      .sort((a, b) => a.name.localeCompare(b.name));

    const dialogConfig = new MatDialogConfig();
    this.dialog_$.dialog.openDialogs.pop();
    dialogConfig.minWidth = '350px';
    dialogConfig.autoFocus = true;
    dialogConfig.data = treeData;

    const dialogRef = this.dialog_$.open(FolderSelectorComponent, dialogConfig);

    dialogRef
      .keydownEvents()
      .toPromise()
      .then(e => {
        if (!e) return;

        if (e.key === 'Escape') {
          e.preventDefault();
          dialogRef.close();
        }
      });

    dialogRef.afterClosed().subscribe(async folderId => {
      // NOTE: Targeting the root is not allowed.
      if (folderId === '') return;

      switch (type) {
        case FileActionsDictionary.COPY:
          // NOTE: Run copy action.
          await this.copySelectedTo(folderId);
          break;
        case FileActionsDictionary.MOVE:
          // NOTE: Run move action.
          await this.moveSelectedTo(folderId);
          break;
        default:
          break;
      }

      this.browseFolderContents(folderId, this.getFolderName(this.caseFolders, folderId));

      // NOTE: Refresh ActiveConsultants:
      if (!this.userIsConsultant) this.updateConsultantsLists();
    });
  }

  setPaging(files: any[], folderId: string, folderName: string) {
    const filesWithName = files.map(file => ({ ...file, name: file.fileName }));
    this.updateFiles(filesWithName);
    this.files_$.addPatientFiles(this.files);
    // this.filesTableDataSource = new MatTableDataSource(this.files.slice());
    if (folderId === this.homeFolderName) {
      const sortedFolders = this.sortingIndexFolders(this.files);
      this.filesTableDataSource = new MatTableDataSource(sortedFolders);
      // this.filesTableDataSource = new MatTableDataSource(this.files);
    } else {
      this.filesTableDataSource = new MatTableDataSource(this.files.slice());
      this.updateDataTable();
    }

    this.currentFolder = folderId;
    this.currentFolderName = folderName || this.homeFolderName;
    const count = this.currentFolder === this.homeFolderName ? this.files.length + 1 : this.files.length;
    this.setPageSize(count);
  }

  /**
   * Use sort function by specific criteria.
   */
  setSort(criteria: string, direction) {
    this.sort.sort({ id: null, start: direction, disableClear: false });
    this.sort.sort({ id: criteria, start: direction, disableClear: false });
    this.updatePaginator();
  }

  showInfectedFiles(files) {
    this.dialog_$
      .open(SimpleMessageWindowComponent, {
        width: '550px',
        disableClose: true,
        data: {
          title: 'Malicious File Detected',
          message: 'The following files are infected and has been deleted:',
          list: files.map(n => ({ name: n })),
          buttons: [{ text: 'CLOSE AND REFRESH', value: 'refresh' }],
        },
      })
      .afterClosed()
      .subscribe(result => {
        if (result === 'refresh') this.goRefresh(null);
      });
  }

  showSharedConsultantsByFile(file) {
    if (Object.keys(file).length === 0) return this.sharing_files_$.pickedFile.next(file);

    if (!this.activeConsultants) return;

    const scEmails = this.activeConsultants.map(c => c.email);

    if (scEmails.length) this.getMissingSharedFiles(file);

    this.pickedSharedFile = file;
    this.sharing_files_$.pickedFile.next(this.pickedSharedFile);
  }

  getSortedData(data, sort) {
    return data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'isShared':
          return this.compare(a.isShared, b.isShared, false);
        case 'fdate':
          return this.compareDates(
            a.ftime ? a.fdate + ' ' + a.ftime : a.fdate,
            b.ftime ? b.fdate + ' ' + b.ftime : b.fdate,
            isAsc,
          );
        case 'predefined':
          return this.compare(a.predefined, b.predefined, isAsc);
        case 'fileDesc':
          return this.compare(a.fileDesc, b.fileDesc, isAsc);
        case 'lastModified':
          return this.compare(a.lastModified, b.lastModified, isAsc);
        case 'uploadedDate':
          return this.compareDates(a.uploadedDate, b.uploadedDate, isAsc);
        case 'parentFolderName':
          return this.compare(a.parentFolderName, b.parentFolderName, isAsc);
        case 'ftype':
          return this.compare(a.ftype, b.ftype, isAsc);
        case 'fileName':
          return this.compare(a.fileName, b.fileName, isAsc);
        default:
          return 0;
      }
    });
  }

  handleSortData(sort: Sort) {
    const data = this.files.slice();
    if (!sort.active || sort.direction === '') {
      this.sortedData = data;
      return;
    }

    this.sortedData = this.getSortedData(data, sort);
    this.filesTableDataSource.data = this.sortedData;
    this.updatePaginator();
    if (this.filesTableDataSource.paginator) {
      this.filesTableDataSource.paginator.firstPage();
    }
    this.ref.detectChanges();
    this.selection.clear();
    this.refreshButton = true;
    this.refreshing = false;
  }

  /**
   * Gets the data and:
   * 1. Isolate index link.
   * 2. Isolate predefined items and sort them alphabetically.
   * 3. Isolate custom folders and sort them alphabetically.
   * 4. Join 1, 2 and 3 keeping that order.
   * @param data is an array of files.
   */
  sortingIndexFolders(data) {
    let indexItem;
    if (this.realRole === UserRoles.client) {
      indexItem = false;
    } else {
      let indexINDEX = 0;
      indexItem = data.filter((file, i) => {
        if (file.fileName.substring(0, 5) === indexFolderLabel) {
          indexINDEX = i;
          return true;
        }
      })[0];
      data.splice(indexINDEX, 1);
    }
    const predefinedItems = data.filter(({ predefined }) => predefined === true);
    const customItems = data.filter(({ predefined }) => predefined !== true);
    const predefinedSortedItems = predefinedItems.slice().sort((a, b) => this.compare(a.fileName, b.fileName, true));
    const customSortedItems = customItems.slice().sort((a, b) => this.compare(a.fileName, b.fileName, true));

    return (indexItem ? [indexItem] : []).concat(predefinedSortedItems).concat(customSortedItems);
  }

  /**
   * Once you have selected one or more files, this action will
   * place selected one at the top of the list followed by the
   * non selected ones.
   */
  sortFilesBySelected() {
    const selectedItems = this.selection.selected;
    const selected = selectedItems.map(item => item.fileId);
    const data = this.filesTableDataSource.data.filter(item => !selected.includes(item.fileId));
    this.filesTableDataSource.data = selectedItems.concat(data);
  }

  toggleHighLight(event, file) {
    event.preventDefault();
    event.stopPropagation();

    const classList = event.target.classList;

    if (classList.contains('on')) {
      this.cleanHighlighted();
      this.showSharedConsultantsByFile({});
    } else {
      this.cleanHighlighted();
      event.target.classList.add('on');
      this.showSharedConsultantsByFile(file);
    }

    this.focusActiveConsultantsTab();
    this.cleanHighlightedActiveConsultants();
    this.activeConsultant = null;
  }

  async unShareSingleFile(row) {
    this.auth_$.showLoader('Unsharing file...');
    const activeConsultant = this.activeConsultant.toLowerCase();
    await this.firebase_utilities_$.unShareFile(row, activeConsultant, this.casename);
    console.log('unShareSingleFile -- getFilesForPatient');
    this.getFilesForPatient(this.currentFolder, this.getSharingFilesOptions()).then(() =>
      this.afterGetFilesForPatient(activeConsultant, row),
    );
  }

  updateActiveConsultant(consultant?, sort?) {
    if (!sort && !consultant) {
      this.activeConsultant = null;
      this.setSort('fileName', 'desc');
      return;
    }

    this.activeConsultant = consultant || null;
    this.updateDataSharedStatus();
    this.setSort(sort.active, sort.direction);
  }

  updateFile(
    data: { type: string; date: { value: string | number | Date }; name: any; desc: any },
    file: { folderId: string; fileId: string },
  ) {
    const afterUpdate = async () => {
      const folderName = this.getFolderName(this.caseFolders, this.currentFolder);
      const message = successmessage + ' Reloading this list...';
      const label = 'INFORMATION';
      this.uiMessaging_$.toastMessage(message, label);
      console.log('updateFile -- getFilesForPatient');
      await this.getFilesForPatient(this.currentFolder, {
        folderName,
        caseFolders: this.caseFolders,
        roleConsultant: this.userIsConsultant,
        roleClient: this.userIsClient,
      });
      this.auth_$.snackBar.dismiss();
      if (!this.userIsConsultant) {
        await this.updateConsultantsLists();
      }
      return successmessage;
    };

    const successmessage = data.type === 'folder' ? 'Folder updated successfully' : 'File updated successfully.';

    let ndate = null;
    if (data.date) {
      const date = new Date(data.date.value);
      ndate = `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
    }

    if (data.type === 'folder') {
      this.files_$.getFilesByFolderId(file.folderId).then(fileSnapShot => {
        fileSnapShot.forEach(item => {
          const updateObject = {};
          if (data.name) {
            updateObject['name'] = data.name;
          }
          this.files_$
            .updateFileByDocId(item.id, updateObject)
            .then(() => afterUpdate())
            .catch(error => this.handleError(error));
        });
      });
    } else {
      this.files_$.getFileByFileId(file.fileId).then(fileSnapShot => {
        fileSnapShot.forEach(item => {
          const updateObject = {};

          if (data.name) {
            updateObject['fileName'] = data.name;
          }

          if (data.desc) {
            updateObject['fileDesc'] = data.desc;
          }

          if (ndate) {
            updateObject['fdate'] = ndate;
          }

          this.files_$
            .updateFileByDocId(item.id, updateObject)
            .then(() => afterUpdate())
            .catch(error => this.handleError(error));
        });
      });
    }
  }

  async updateConsultantsLists() {
    if (this.currentFolder === '') {
      console.log('The current folder is Homepage and there is no need of getting the consultants list');
      return;
    }

    // FIXME: Get the list of active consultants doesn't get all.
    this.activeConsultants = await this.getActiveConsultants();
    this.availableConsultants = await this.getAvailableConsultants();
  }

  // FIXME: In case of 'all' should not touch those files already unshared.
  async unShareWith(params) {
    const { consultant, type } = params;
    const array = type === 'single' ? this.selection.selected : this.filesTableDataSource.data;
    const filesToUnShare = [];
    const result = [];
    for (let index = 0; index < array.length; index++) {
      if (array[index].sharedUsers && array[index].sharedUsers.length === 0) {
        continue;
      }

      if (this.utils_$.fileIsShared({ email: consultant }, array[index])) {
        result.push(array[index].fileName);
        await this.firebase_utilities_$.unShareFile(array[index], consultant, this.casename);
        filesToUnShare.push(array[index]);
      } else {
        continue;
      }
    }
    this.whenUnShareFilesAreDone(result, consultant);
    const filesGroup = filesToUnShare.map(item => item.fileName);
    this.log_$.storeLog(
      this.auth_$.userData.getValue(),
      LogLevelsDictionary.info,
      {
        message: 'A group of files has been unshared.',
        filenames: filesGroup.join(','),
        user: consultant,
      },
      'Unshare',
      this.casename,
    );
    const folderName = this.getFolderName(this.caseFolders, this.currentFolder);
    console.log('unShareWith -- getFilesForPatient');
    this.getFilesForPatient(this.currentFolder, {
      folderName,
      caseFolders: this.caseFolders,
      roleConsultant: this.userIsConsultant,
      roleClient: this.userIsClient,
    });

    if (!this.userIsConsultant) {
      this.updateConsultantsLists();
    }
  }

  async unShareAll(consultantEmail: string) {
    this.unShareWith({ consultant: consultantEmail, type: 'all' }).then(() => (this.activeConsultant = null));
  }

  updateDataSharedStatus() {
    const newData = [];
    this.filesTableDataSource.data.forEach(item => {
      item.isShared =
        typeof item.sharedUsers === 'undefined' ? false : this.isSharedWithActiveConsultant(item.sharedUsers);

      newData.push(item);
    });

    this.filesTableDataSource.data = newData;
    this.sharedFilesCount = this.countSharedFiles(this.filesTableDataSource.data);
  }

  updatePaginator() {
    this.filesTableDataSource.paginator = this.uppaginator;
  }

  updateDataTable() {
    this.sortedData = this.files.slice();
    this.filesTableDataSource.data = this.files;
    this.updatePaginator();

    if (this.filesTableDataSource.paginator) this.filesTableDataSource.paginator.firstPage();

    this.ref.detectChanges();
    this.selection.clear();
    this.refreshButton = true;
    this.refreshing = false;
  }

  validateRole(role: string) {
    return this.permissions_$.validateRole(role);
  }

  async validateRoleMulti(objectList: any) {
    return await this.permissions_$.validateRoleMulti(objectList);
  }

  handleNoteClick(event, element) {
    event.stopPropagation();
    event.preventDefault();
    if (element.disabled) return;

    this.ngZone.run(async () => {
      this.auth_$.showLoader('Loading comments and attachments...');
      element.disabled = true;
      this.selection.selected.push(element);

      if (environment.config.viewerIndex === 0) {
        await this.preStudyViewAction();
      }

      this.openCommentsAndAttachments(element).then(() => {
        element.disabled = false;
        this.auth_$.hideLoader();
        this.getRidOfNotesNotifications(element);
      });
    });
  }

  handleAddClientUser(event) {
    console.log('event :', event);
    console.log('handleAddClientUser');
    const dialog = this.dialog_$.open(CreateCuaUserComponent, {
      width: '600px',
      data: {
        patient: this.patient,
        user: event,
      },
    });

    dialog.afterClosed().subscribe(async ({ success, result }) => {
      if (success) {
        const { email } = result;
        await this.clientMatter_$.updateClientMatter(this.casename, email).then(res => {
          console.log('Patient updated :', res);
          this.updateClientData(email);
        });

        if (this.ownerHasAccess) {
          await this.createClientUploadFolder(this.casename)
            .then(res => {
              console.log('Folder created :', res);
              this.updateFoldersList().then(() => this.getData());
            })
            .catch(err => {
              console.error('Error creating folder :', err);
            });
        }
      }
    });
  }

  private async updateFoldersList() {
    this.caseFolders = await this.handlingFolders();
  }

  private async createClientUploadFolder(casename) {
    const result = await this.folders_$.createFolder(
      'Client Upload',
      casename,
      {
        color: folderColors['Client Upload'],
        type: 'folder',
        predefined: true,
        forDelete: false,
        modal: false,
      },
      this.caseFolders,
    );
    return result;
  }

  private updateClientData(clientUserEmail) {
    this.patient.clientUser = clientUserEmail;
  }

  private async createNewFolder(
    folderName: string,
    caseName: string,
    options = {
      color: '#c5f0f6',
      type: 'folder',
      predefined: false,
      forDelete: false,
      modal: false,
    },
  ) {
    if (this.checkDuplicatedFolder(folderName)) {
      if (options.modal) this.uiMessaging_$.toastMessage(`Folder name ${folderName} is already taken`, ``);
      return;
    }

    this.auth_$.showLoader(`Creating folder ${folderName}...`);
    const folderId = this.uploadHelper_$.getRandomString(20);
    const result = await this.firebase_utilities_$.createFolder({
      children: ``,
      parentFolder: ``,
      folderId: folderId,
      belongsTo: caseName,
      name: folderName.trim(),
      color: options.color,
      type: options.type,
      predefined: options.predefined,
      forDelete: options.forDelete,
    });
    this.auth_$.hideLoader();
    return result;
  }

  getDefinedDefaultFolders(userRole) {
    console.log('getDefinedDefaultFolders userRole :', userRole);
    return this.firebase_utilities_$.getDefaultFoldersDefinition(userRole);
  }

  async getDefaultFolders() {
    return (await firebase.firestore().collection('defaultFolders').where('business', '==', 'legal').get()).docs.map(
      d => d.data(),
    );
  }

  async handleFolder(folder, params, useremail, filesShared) {
    const folderExtra = await this.getFolderExtra(folder, params.roleConsultant, useremail, filesShared);
    return { ...folder, ...folderExtra };
  }

  private getSeachFilesComponentData() {
    return {
      id: 1,
      uploadTypes: this.uploadTypes,
      fdate: this.fdate,
      ftime: this.ftime,
      basicfile: this.files,
      description: this.fdesc,
      type: this.ftype,
      folder: this.currentFolder,
      clientName: this.patientName,
      casename: this.casename,
      action_params: {
        files: '',
        type: '',
        desc: '',
        caseName: this.casename,
        creator: this.uid,
      },
    };
  }

  // NOTE: This is used from template too .
  public async handleRefresh() {
    this.getFilesForPatient(this.currentFolder, {
      folderName: this.currentFolderName,
      roleConsultant: this.realRole === UserRoles.consultant,
      caseFolders: await this.getCaseFolders(),
    });
  }

  private async updateFilesArrayNotifications() {
    await this.auth_$.getNotifications();

    const updatedFiles = this.files.map(file => ({
      ...file,
      hasNotifications: environment.config.notifications === 0 ? false : this.getFileHasNotifications(file),
    }));

    this.updateFiles(updatedFiles);
    this.filesTableDataSource.data = this.files;
    await this.handleRefresh();
  }

  private getFileHasNotifications(file) {
    return this.getAllNotificationsFromUserData().some(n => n.fileId === file.fileId);
  }

  private checkIfFolderHasNotifications(folderId: string) {
    if (environment.config.notifications === 0) {
      return false;
    }
    return (
      this.auth_$.userData
        .getValue()
        ['notifications'].map(n => n.folderId)
        .indexOf(folderId) > -1
    );
  }

  private getUserDataNotifications(fileId) {
    return this.auth_$.userData.getValue()['notifications'].filter(n => n.fileId === fileId);
  }

  private getAllNotificationsFromUserData() {
    return this.auth_$.userData.getValue()['notifications'];
  }

  private afterAddNote({ fileDocId, id, type, parentFolder, noteId }) {
    this.selection.clear();
    const folderName = this.currentFolderName;
    console.log('openAddNoteDialog -- getFilesForPatient');

    // Create notification.

    this.firebase_utilities_$.createNotifications(
      { id: id },
      { type: type === 'file' ? 1 : 2, uploadBlock: { casename: this.casename }, noteId },
      { parentFolderId: parentFolder },
      NotificationTypes.newnote,
    );

    this.getFilesForPatient(this.currentFolder, {
      folderName,
      caseFolders: this.caseFolders,
      roleConsultant: this.userIsConsultant,
      roleClient: this.userIsClient,
    });
    if (!this.userIsConsultant) this.updateConsultantsLists();
  }

  private async populateAllNotesDialogData(fileClicked?): Promise<ViewAllNotesDialogData> {
    const { fileName, viewerurl, ftype, fileId, id, notes, parentFolder, type } = this.selection.selected[0];
    const { uid, role, email, notifications } = <{ uid: string; role: string; email: string; notifications: any }>(
      this.auth_$.userData.getValue()
    );

    const dialogData = {
      title: 'View Comments & Attachments',
      message: `Filename: ${fileName}`,
      selected_files: this.selection.selected,
      notes: notes,
      fileViewData: await this.getfileViewData(ftype, fileName, viewerurl, fileId),
      fileid: fileId,
      fileDocId: id,
      patientName: this.casename,
      createdBy: email,
      currentFolder: this.currentFolder,
      currentFolderName: this.currentFolderName,
      parentFolder: parentFolder,
      userRole: role,
      userEmail: email,
      fileType: type,
      notifications: this.getNotificationsFiltered(notifications, fileId, uid),
      restrictedProfiles: this.patient.restrictedProfiles,
      allowedProfiles: this.patient.allowedProfiles,
      fileClicked: fileClicked || null,
    };

    return dialogData;
  }

  private allNotesCloseHandler(val: { fileId: any }) {
    this.selection.clear();
    console.log('openAllNotes -- getFilesForPatient');
    this.auth_$.getNotifications(val?.fileId);
    this.getFilesForPatient(this.currentFolder, {
      folderName: this.currentFolderName,
      roleConsultant: this.userIsConsultant,
      roleClient: this.userIsClient,
      user: this.user,
      caseFolders: this.caseFolders,
    });
    if (!this.userIsConsultant) this.updateConsultantsLists();
  }

  /**
   *
   *
   * @private
   * @return {*}
   * @memberof ClientProfileComponent
   */
  private getOptions(): { folderName: string; caseFolders: any[]; roleConsultant: boolean; roleClient: boolean } {
    return {
      folderName: this.getFolderName(this.caseFolders, this.currentFolder),
      caseFolders: this.caseFolders,
      roleConsultant: this.userIsConsultant,
      roleClient: this.userIsClient,
    };
  }

  /**
   * If user is consultant, then the consultants lists are not updated. They doesn't exist.
   *
   * @private
   * @memberof ClientProfileComponent
   */
  private evaluateConsultantsListsUpdate() {
    if (!this.userIsConsultant) this.updateConsultantsLists();
  }

  /**
   *
   *
   * @private
   * @param {any[]} promisesResult
   * @param {string} consultantEmail
   * @memberof ClientProfileComponent
   */
  private handleStoreLog(promisesResult: any[], consultantEmail: string) {
    this.log_$.storeLog(
      this.auth_$.userData.getValue(),
      LogLevelsDictionary.info,
      {
        message: 'A group of files has been shared.',
        filenames: promisesResult.join(','),
        user: consultantEmail,
      },
      'Share',
      this.casename,
    );
  }

  private getActiveConsultants() {
    return this.firebase_utilities_$.getActiveConsultantsAndClientByClientId(
      UserRoles.consultant,
      this.casename,
      this.ownerID,
    );
  }

  private getAvailableConsultants() {
    return this.firebase_utilities_$.getAvailableConsultantsAndClient(this.casename, this.activeConsultants);
  }

  deleteAllFileNotifications(fileId: string) {
    return this.notifications_$.deleteAllFileNotifications(fileId);
  }

  private handleDeleteAllFilesNotifications(fileIDs: any[]) {
    const deleteAllFilesNotifications = [];
    fileIDs.forEach(fileID => deleteAllFilesNotifications.push(this.deleteAllFileNotifications(fileID)));
    Promise.all(deleteAllFilesNotifications).then(() => {
      this.auth_$.getNotifications();
    });
  }

  async reloadProfiles() {
    this.patient = {
      ...this.patient,
      ...(await firebase.firestore().collection('patients').doc(this.patient.patientDocId).get()).data(),
    };
  }

  async reloadConsultantsData($event) {
    await this.reloadProfiles();
    this.uiMessaging_$.toastMessage('Consultants list updated.', 'INFO');
  }

  validateProfile(notes) {
    if ([UserRoles.consultant, UserRoles.client].includes(this.auth_$.userData.getValue()['role'])) {
      if (Boolean(this.isAuthor(notes))) {
        return true;
      }

      if (this.isRestrictedProfile()) {
        return false;
      }

      if (this.patient.allowedProfiles?.includes(this.auth_$.userData.getValue()['uid'])) {
        return true;
      }

      return true;
    }
    return true;
  }

  private isRestrictedProfile() {
    return this.patient.restrictedProfiles?.includes(this.auth_$.userData.getValue()['uid']);
  }

  private isAuthor(notes) {
    return notes.find(n => n.createdBy === this.auth_$.userData.getValue()['email']);
  }

  private getPromise(uid, newFileNotifications?, sharedFileNotifications?, fileId?) {
    if (newFileNotifications && sharedFileNotifications) {
      return this.notifications_$.deleteNewFileAndSharedFileNotificationsByFileIdAndUserId(fileId, uid);
    } else if (newFileNotifications) {
      return this.notifications_$.deleteNewFileNotificationsByFileIdAndUserId(fileId, uid);
    } else if (sharedFileNotifications) {
      return this.notifications_$.deleteSharedFileNotificationsByFileIdAndUserId(fileId, uid);
    } else {
      return Promise.resolve('');
    }
  }

  private handleDeleteNewFileAndSharedFileNotifications({ newFileNotifications, sharedFileNotifications, fileId }) {
    const uid = this.auth_$.userData.getValue()['uid'];
    this.getPromise(uid, newFileNotifications, sharedFileNotifications, fileId).then(() => {
      this.updateFilesArrayNotifications();
    });
  }

  private getPatientDocIdFromCaseName(casename: string) {
    return this.firebase_utilities_$.getPatientDocIdFromCaseName(casename);
  }

  private async afterProfileUpdated() {
    this.activeConsultants = await this.getActiveConsultants();
  }

  private prepareSimpleFileData(fileName, fileId) {
    const fileExtension = this.getFileNameExtension(fileName);
    if (notPlayableVideoExtensions.includes(fileExtension)) {
      return this.prerareVOBDownloadLink(fileExtension);
    } else {
      return this.prepareRegularFileViewerData(fileId, fileName, fileExtension);
    }
  }
  private prepareDICOMDIskData(viewerurl) {
    // Preparing the viewer url.
    return {
      type: 'dicomdisk',
      docviewer: false,
      downloadLink: false,
      viewerUrl: this.getDICOMDiskViewerUrl(viewerurl),
    };
  }

  private getDICOMDiskViewerUrl(viewerurl: string) {
    return this.getViewerUrl(viewerurl);
  }
  private prerareVOBDownloadLink(fileExtension: string) {
    // Prepare Download Link
    return {
      type: fileExtension.toLowerCase(),
      docviewer: false,
      downloadLink: this.getVOBDownloadLink(),
    };
  }

  private async prepareRegularFileViewerData(fileId, fileName, fileExtension) {
    this.auth_$.showLoader('Loading viewer...');
    const type = ['png', 'jpg', 'jpeg', 'png', 'gif'].includes(fileExtension) ? 'image' : 'regular';
    const filePath = (await this.files_$.getFileByFileId(fileId)).docs[0].data().filePath;
    const fileUrl = await this.files_$.getFileUrl(filePath);
    return {
      type,
      docviewer: true,
      viewerData: {
        title: fileName,
        src: fileUrl.data.url,
        info: fileUrl.data.url,
        viewer: this.getProperViewer(fileName),
      },
    };
  }

  private getVOBDownloadLink() {
    return 'https://www.w3schools.com/html/mov_bbb.mp4';
  }

  private createDialogConfig({ width, maxWidth, maxHeight, autoFocus, data, disableClose, height }) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = disableClose;
    dialogConfig.width = width;
    dialogConfig.height = height;
    dialogConfig.maxWidth = maxWidth;
    dialogConfig.maxHeight = maxHeight;
    dialogConfig.autoFocus = autoFocus;
    dialogConfig.data = data;
    return dialogConfig;
  }

  private async getfileViewData(ftype, fileName, viewerurl, fileId) {
    this.auth_$.showLoader('Loading viewer...');
    const preparingViewerData =
      ftype === UploadTypes.DICOMDisk
        ? Promise.resolve(this.prepareDICOMDIskData(viewerurl))
        : await this.prepareSimpleFileData(fileName, fileId);
    return preparingViewerData;
  }

  private getNotificationsFiltered(notifications, fileId, uid) {
    return notifications
      .filter((g: { fileId: string }) => g.fileId === fileId)
      .filter(({ userId }) => userId === uid)
      .filter(({ type }) => [NotificationTypes.modifiednote, NotificationTypes.newnote].includes(type));
  }

  private async getFileUrl(filePath) {
    return firebase.functions().httpsCallable('firebaseUtilities-fileGetSignedURL')({ filePath });
  }

  private getAttachmentLists(attachments) {
    return attachments ? this.firebase_utilities_$.storeNoteNewAttachments(attachments, this.patientName) : [];
  }

  private async validateFileMeta(filePath) {
    const fileMetadata = (await firebase.functions().httpsCallable('firebaseUtilities-fileGetMetadata')({ filePath }))[
      'data'
    ]['url'];
    this.auth_$.hideLoader();

    if (!fileMetadata) {
      this.auth_$.hideLoader();
      this.uiMessaging_$.toastMessage(
        "It seems the file doesn't exist anymore. Please delete the file and import it again",
        'ERROR',
      );
      return false;
    }

    if (+fileMetadata['size'] === 0) {
      this.auth_$.hideLoader();
      this.uiMessaging_$.toastMessage(
        "It seems the file doesn't exist anymore. Please delete the file and import it again",
        'ERROR',
      );
      return false;
    }

    return true;
  }

  private getRidOfNotesNotifications(element, notificationTypes?: string[]) {
    this.deleteNotificationsByFileId(element.fileId, notificationTypes || null);
  }

  private deleteNotificationsByFileId(fileId, notificationTypes?: string[]) {
    this.firebase_utilities_$.deleteNotificationsByTypeUserIdAndFileId(
      notificationTypes || [NotificationTypes.newnote, NotificationTypes.modifiednote, NotificationTypes.newfile],
      this.user.uid,
      fileId,
    );
    this.excludeNotificationsFromUserDataByFileId(
      fileId,
      notificationTypes || [NotificationTypes.newnote, NotificationTypes.modifiednote, NotificationTypes.newfile],
    );
  }

  private excludeNotificationsFromUserDataByFileId(fileIdParameter, notificationTypes?: string[]) {
    const notifications = this.getAllNotificationsFromUserData().filter(
      ({ fileId, type }) => fileId !== fileIdParameter && !notificationTypes.includes(type),
    );
    this.auth_$.userData.next({ ...this.auth_$.userData.getValue(), notifications });
  }

  private async getFilesSharedByCaseName(casename: string) {
    const filesForCase = this.auth_$.userData.value['filesSharedWith'].filter(
      (file: { patientCaseName: string }) => file.patientCaseName === casename,
    );

    for (let index = 0; index < filesForCase.length; index++) {
      const folderId = await this.getParentFolderByFileId(filesForCase[index].fileId);
      filesForCase[index].folderId = folderId;
    }

    return filesForCase;
  }

  private getParentFolderByFileId(fileId: string) {
    return this.files_$.getParentFolderByFileId(fileId);
  }

  async getFilesHomeFolder(params): Promise<any> {
    let indexCount, filesShared;
    const useremail = typeof this.user === 'undefined' ? undefined : this.user.email || '';

    if (params.roleConsultant) {
      filesShared = await this.getFilesSharedByCaseName(this.casename);
      indexCount = filesShared.length.toString();
      const filesParentFolderIds = await this.files_$.getParentFolders(filesShared.map(file => file.fileId));
      this.sharedFolders = [];

      for (let index = 0; index < params.caseFolders.length; index++) {
        if (filesParentFolderIds.includes(params.caseFolders[index].folderId)) {
          let folder = params.caseFolders[index];

          // Add the count to the folder name.
          const count = filesShared.filter(file => file.folderId === folder.folderId).length;
          folder = { ...folder, name: `${this.files_$.findFolderAlias(folder.name)} (${count})` };

          this.sharedFolders.push(folder);
        }
      }

      params.caseFolders = this.sharedFolders;
    } else indexCount = (await this.getFilesCountByClient(this.casename, params.roleConsultant, useremail)).toString();

    await this.setFoldersWithCountLabels(indexCount, this.realRole, params.roleConsultant, params.caseFolders);
    const folders = await this.getNamedFolders(params, useremail, filesShared);
    return this.ownerHasAccess ? folders : folders.filter(f => f.fileName !== 'Client Upload');
  }

  private async getNamedFolders(params, useremail, filesShared) {
    const folderList = await this.getFolders(params, useremail, filesShared, this.files);
    return folderList.map((g: { fileName: string }) => {
      return { ...g, name: g.fileName };
    });
  }

  private getFolders(params, useremail, filesShared, files) {
    return Promise.all(files.map(async folder => this.handleFolder(folder, params, useremail, filesShared)));
  }

  private setFiles(foldersWithCountLabels, indexCount) {
    return this.realRole === UserRoles.client
      ? foldersWithCountLabels
      : [this.createIndexFolder(indexCount), ...foldersWithCountLabels];
  }

  private async setFoldersWithCountLabels(indexCount, role, roleConsultant, caseFolders) {
    const folders = await this.setFolderCountLabels(roleConsultant, caseFolders);
    this.updateFiles(role === UserRoles.client ? folders : [this.createIndexFolder(indexCount), ...folders]);
  }

  private createIndexFolder(indexCount) {
    return {
      type: 'folder',
      name: `${indexFolderLabel} (${indexCount})`,
      folderId: INDEX_FOLDER_NAME,
      color: indexFolderColor,
      predefined: true,
    };
  }

  private createFolderObject(folderName) {
    return {
      children: '',
      name: folderName,
      color: '#c5f0f6',
      parentFolder: '',
      type: 'folder',
      belongsTo: this.casename,
      predefined: false,
      forDelete: false,
      folderId: this.uploadHelper_$.getRandomString(20),
    };
  }

  private getFileWithNotifications(file) {
    const notifications = this.getUserDataNotifications(file.fileId);
    const categorizedNotifications = this.getCategorizedNotifications(notifications);
    return {
      ...file,
      ...categorizedNotifications,
    };
  }

  private getCategorizedNotifications(n: { type: NotificationTypes }[]) {
    if (environment.config.notifications === 0) {
      return {
        newNoteNotifications: [],
        newFileNotifications: [],
        modifiedNoteNotifications: [],
        sharedFileNotifications: [],
      };
    }
    return {
      newNoteNotifications: n.filter(n => n.type === NotificationTypes.newnote),
      newFileNotifications: n.filter(n => n.type === NotificationTypes.newfile),
      modifiedNoteNotifications: n.filter(n => n.type === NotificationTypes.modifiednote),
      sharedFileNotifications: n.filter(n => n.type === NotificationTypes.sharedfile),
    };
  }

  private getOwnerAccess() {
    this.auth_$
      .doesOwnerHasAccess()
      .then(res => (this.ownerHasAccess = res))
      .catch(err => (this.ownerHasAccess = false));
  }

  handleParentFolderNameClick(event, parentFolder) {
    event.preventDefault();
    this.browseFolderByName(parentFolder.folderName);
  }

  getOwnerID() {
    return this.patient.ownerID;
  }

  private whenShareFilesAreDone(result, email) {
    this.sendFileSharingEmailNotification({ email, casename: this.casename, files: result.join(', ') });
    this.handleStoreLog(result, email);
    this.getFilesForPatient(this.currentFolder, this.getOptions());
    this.evaluateConsultantsListsUpdate();
  }

  private sendFileSharingEmailNotification({ email, casename, files }) {
    const messageData = { targetEmail: email, caseName: casename, files: files };
    this.firebase_utilities_$.sendEmailNotification(messageData, 'share');
  }

  private generateShareFilePromises(email) {
    const promisesStack = [];
    const filesToShare = this.selection.selected;
    filesToShare.forEach(selectedFile =>
      promisesStack.push(this.firebase_utilities_$.shareFile(selectedFile, email, this.casename)),
    );
    return promisesStack;
  }

  private getSharingFilesOptions() {
    return {
      folderName: this.getFolderName(this.caseFolders, this.currentFolder),
      caseFolders: this.caseFolders,
      roleConsultant: this.userIsConsultant,
      roleClient: this.userIsClient,
    };
  }

  private afterShareFile(row) {
    // this.sendFileSharingEmailNotification({ email, casename: this.casename, files: result.join(', ') });

    const folderName = this.getFolderName(this.caseFolders, this.currentFolder);
    console.log('shareSingleFile -- getFilesForPatient');
    this.getFilesForPatient(this.currentFolder, {
      folderName,
      caseFolders: this.caseFolders,
      roleConsultant: this.userIsConsultant,
      roleClient: this.userIsClient,
    }).then(() => {
      this.updateActiveConsultant(this.activeConsultant, { active: 'isShared', direction: 'desc' });
      if (!this.userIsConsultant) this.updateConsultantsLists();
      this.utils_$.createNotificationEmail('SHARE', row, this.activeConsultant);
      this.log_$.storeLog(
        this.auth_$.userData.getValue(),
        LogLevelsDictionary.info,
        { message: 'A file has been shared.', filename: row.fileName, user: this.activeConsultant },
        'Share',
        this.casename,
      );
    });
  }

  private afterGetFilesForPatient(activeConsultant, row) {
    const sort = { active: 'isShared', direction: 'desc' };
    this.updateActiveConsultant(activeConsultant, sort);

    if (!this.userIsConsultant) this.updateConsultantsLists();

    this.utils_$.createNotificationEmail('UNSHARE', row, activeConsultant);

    const logObject = {
      message: 'A file has been unshared.',
      filename: row.fileName,
      user: activeConsultant,
    };

    this.log_$.storeLog(
      this.auth_$.userData.getValue(),
      LogLevelsDictionary.info,
      logObject,
      'Unsharing',
      this.casename,
    );

    this.auth_$.hideLoader();
  }

  private countSharedFiles(files) {
    return files.filter(file => file.isShared).length;
  }

  private noEvent() {
    this.activeConsultant = null;
    if (this.currentFolder === '') this.setSort('predefined', 'desc');
    else if (this.currentFolder === 'all') this.setSort('parentFolderName', 'asc');
    else this.setSort('fileName', 'desc');
    this.selectionRecord.forEach(record => this.selection.toggle(record));
    return;
  }

  private cleanHighlightedActiveConsultants() {
    this.active_consultants.selectedOptionsClear();
  }

  async goToFolder({ folderId, consultant }) {
    this.handleFolderClickV2(new Event('click'), {
      name: folderId === 'all' ? 'INDEX' : folderId,
      folderId,
      predefined: true,
    }).then(() => {
      this.active_consultants.currentValue = '';
      this.active_consultants.handleOptionClick(consultant, new Event('click'));
    });
  }

  async browseFolderContentsV2(folderId: string, folderName: string) {
    const getOptions = () => {
      return {
        globalFolders: true,
        folderName: folderName,
        roleConsultant: this.userIsConsultant,
        roleClient: this.userIsClient,
        caseFolders: this.caseFolders,
      };
    };
    this.activeConsultant = null;
    this.selection.clear();
    console.log('browseFolderContents', folderName);
    this.auth_$.setCurrentFolder(folderName);
    await this.getFilesForPatient(folderId, getOptions(), true);
  }

  private updateFileField(fileId: string, field: any) {
    this.files_$.updateFileField(fileId, field);
  }

  handleDownloadDIscFinished(res, fileId) {
    if (!res) {
      this.handleError();
      return;
    }
    res.subscribe({
      next: data => {
        console.log('===================');
        console.log({ data });
        console.log('===================');

        const { fileInfo } = data;
        console.log('fileInfo:', fileInfo);

        if (data.dataResult?.status === 200)
          this.uiMessaging_$?.toastMessage('The disc is ready to be downloaded.', 'INFO');
        else this.handleError();
      },
      error: (error: HttpErrorResponse) => {
        if (error.status === 0) {
          this.uiMessaging_$?.toastMessage(error.statusText, 'ERROR');
          return;
        }
        console.log('===================');
        console.log({ error });
        console.log('===================');

        console.log('error:', { message: error.error.errors[0].message, reason: error.error.errors[0].reason });

        this.disableFile(fileId);
        this.handleError();
      },
      complete: () => console.log('completed.'),
    });
  }

  disableFile(fileId: string) {
    this.updateFileField(fileId, { disabled: true });
  }

  selectedIsStudy() {
    const selectedFiles = this.selection.selected;
    return selectedFiles.length === 1 && (selectedFiles[0]?.fileName === 'DICOMDIR' || selectedFiles[0]?.viewerurl);
  }

  // Constant for error message
  INVALID_SELECTION_ERROR = 'Invalid selection.';

  async generateDISC(evt: Event, selected: any[]): Promise<void> {
    // Prevent the default event behavior
    evt.preventDefault();

    // Check if the selection is valid
    if (!Array.isArray(selected) || selected.length === 0) throw new Error(this.INVALID_SELECTION_ERROR);

    // Start loading notification
    const { downloadLink, fileDesc, fileId, parentFolderName } = selected[0];
    this.showStartGeneratingDiscNotification({ studyName: fileDesc, folderName: parentFolderName, fileId: fileId });
    this.notificationIds.push(fileId);

    const fileWithDICOMDIRFileIdExistince = async (fileId: string): Promise<Boolean> => {
      const result = !(await this.files_$.getFileByDICOMDIRFileId(fileId)).empty ? true : false;
      console.log('fileWithDICOMDIRFileIdExistince result: ', result);
      return result;
    };

    const generatedDiscExists = async (fileId: string) => {
      const booleanAnswer = await fileWithDICOMDIRFileIdExistince(fileId);
      return booleanAnswer;
      // return this.files.some(file => file.DICOMDIRFileId === fileId);
    };

    const deleteDownloadLink = fileId => {
      this.files.find(file => file.fileId === fileId).downloadLink = null;
      return this.files_$?.deleteDownloadLink(fileId);
    };

    // If the file doesn't have a download link, handle it separately
    if (!downloadLink) this.generateDownloadDisc(selected[0]);
    else if (await generatedDiscExists(fileId)) {
      // If the file has a download link, hide the loader and download the file
      this.auth_$.hideLoader();
      console.log('Download the file:', downloadLink);

      const removeLoader = this.auth_$.handleGeneratedDiscCheck({ fileId, fileDesc });
      this.hideStartGeneratingDiscNotification(fileId);
    } else {
      deleteDownloadLink(fileId);
      this.generateDownloadDisc(selected[0]);
    }
  }

  showStartGeneratingDiscNotification(params) {
    const options = { position: 'bottom', fileId: params.fileId };
    const message = `Generating disc contents for the study "${params.studyName}" in the folder "${params.folderName}"`;
    this.auth_$.showProgressLoader(message, options);
  }

  private hideStartGeneratingDiscNotification(fileId: string) {
    this.auth_$.hideProgressLoader(fileId);
  }

  private startWaitingForDiscGeneration(fileInProcess: string) {
    this.files = this.files.map(file => {
      if (file.fileId === fileInProcess) file['waiting'] = true;
      else {
        // The file has not been found, so it's not waiting.
        // FIXME: Add a ui notification here.
      }
      return file;
    });
  }

  async getCaseFolders() {
    this.caseFolders = await this.firebase_utilities_$.getFolders(this.casename);
    return this.caseFolders;
  }

  private getDeleteConfirmDialogConfigData(plural, condition) {
    return {
      title: 'Confirm deletion',
      message: `Are you sure  you want to delete ${this.selection.selected.length} file${plural} from your uploaded files?`,
      confirm_action: condition ? this.handleDeleteAllFiles : this.deleteFiles,
      deleteAllFiles: condition,
      selected_files: condition ? null : this.selection.selected,
      btnOkText: 'Yes',
      btnCancelText: 'No',
    };
  }

  private getFilesForPatientOptions() {
    return {
      folderName: this.getFolderName(this.caseFolders, this.currentFolder),
      caseFolders: this.caseFolders,
      roleConsultant: this.userIsConsultant,
      roleClient: this.userIsClient,
    };
  }

  private deleteFilesFromStorage(filePaths: string[]) {
    return this.files_$.deleteFilesFromStorage(filePaths);
  }

  private gotoDiscsFolder(clientProfileActionsData: any) {
    this.discsFolderFlow = { active: true, fileId: clientProfileActionsData.fileId };
    return this.browseFolderByName(DISCS_FOLDER_NAME, 'uploadedDate').catch(err =>
      console.error('Error browsing folder:', err),
    );
  }

  ngAfterViewChecked() {
    if (this.currentFolderName === DISCS_FOLDER_NAME) {
      if (this.discsFolderFlow) {
        this.ngZone.run(() => this.discsFolderFlowRun(this.discsFolderFlow.fileId));
        this.discsFolderFlow = false;
        this.ref.detectChanges();
      }
    }
  }

  subscribeToClientProfileFiles() {
    this.auth_$.clientProfileFiles.subscribe((files: { originalFileId: string; fileId: string; waiting: any }[]) => {
      this.files.forEach(file => {
        const fileInProcess = files.find(f => f.originalFileId === file.fileId);
        if (fileInProcess) file['waiting'] = fileInProcess.waiting;
      });
      this.filesTableDataSource.data = this.files;
    });
  }

  subscribeToClientProfileActions() {
    this.auth_$.clientProfileActions.subscribe(async action => {
      if (!action) {
        return;
      }

      switch (action) {
        case 'gotodiscs':
          // If current folder is not Discs, then go to Discs.
          if (this.currentFolderName !== DISCS_FOLDER_NAME)
            await this.gotoDiscsFolder(this.auth_$.clientProfileActionsData);
          break;
        case 'generateddiscfirststepready':
          if (!this.helpPanel.expanded) {
            // this.showHelpPanel();
          }

          console.log('generateddiscfirststepready');
          this.howToBurnADisc.howToBurnADiscSteps[0]['running'] = false;
          this.howToBurnADisc.howToBurnADiscSteps[0]['ready'] = true;
          this.auth_$.clientProfileActions.next(null);
          this.auth_$.clientProfileActionsData = null;
          break;
        case 'startthedownload':
          this.auth_$.clientProfileActions.next(null);
          this.auth_$.clientProfileActionsData = null;
          this.handleDerivedZIPFileClick(this.selection.selected[0]);
          break;
        default:
          break;
      }
    });
  }

  private async getPatientsFilesArray(folderId, roleConsultant, caseFolders) {
    let files = await this.getFilesArray(folderId, roleConsultant, caseFolders);

    if (this.filesFilter.valid === false) this.filesFilter.name = '';

    if (this.filesFilter.name !== '' && this.filesFilter.valid === true && this.filesFilter.name === 'DICOM') {
      files = files.filter((file: { fileName: string }) => file.fileName === this.dicomdirFileName);
      this.filesFilter.valid = false;
    }

    return files;
  }

  private showHelpPanel() {
    // FIXME: To show checks beside completed tasks.
    this.howToBurnADisc.howToBurnADiscSteps.forEach(step => (step['completed'] = false));
    this.howToBurnADisc.howToBurnADiscSteps[0]['running'] = true;
    this.helpPanel.open();
    this.clientProfileToolbarComponent.fileActionsMenu['_elementRef'].nativeElement.focus();
  }

  async deleteFolder(folder, event) {
    event.stopPropagation();

    const folderId = folder.folderId;

    if (!folder) return;

    if (!(await this.files_$.checkFolderIsEmpty(folderId))) {
      console.log('You can not delete a folder that is not empty');
      return;
    }

    if (folder.type !== 'folder') {
      this.selection.selected[0] = folder;
      this.openDeleteConfirmDialog();
    } else {
      console.log('Remove folder', folder);
      this.files_$.deleteFolder(folderId).then(() => {
        this.uiMessaging_$.toastMessage('Folder deleted successfully, please refresh this list.', 'IMPORTANT');
        this.getFilesForPatient(this.currentFolder, this.getFilesForPatientOptions());
      });
    }
  }

  fileActionsEnabled() {
    return this.realRole !== this.UserRoles.consultant && UserRoles.client !== this.realRole;
    // return this.filesSelected() && this.realRole !== this.UserRoles.consultant && UserRoles.client !== this.realRole;
  }

  private discsFolderFlowRun(fileId?: string) {
    console.log('===================');
    console.log('discsFolderFlowRun');
    if (fileId) {
      const res = this.selectFile(fileId);
      if (!res) this.uiMessaging_$.toastMessage('The file you are looking for is not in this folder.', 'ERROR');
      else {
        console.log('File selected');
        this.showFileActions('downloadDisc');
      }
    } else console.log('No file selected');
  }

  selectedIsZippedDisc() {
    const selectedFiles = this.selection.selected;
    const answer = selectedFiles.length === 1 && selectedFiles[0]?.DICOMDIRFileId;
    this.activateLastMenuItem(answer);
    return answer;
  }

  private activateLastMenuItem(answer: boolean) {
    if (answer) {
      setTimeout(() => {
        const lastMenuItem = this.clientProfileToolbarComponent.menu.items.last;
        if (!lastMenuItem) return;
        if (lastMenuItem?.getLabel() === this.downloadDiscLabel) {
          lastMenuItem.focus();
          lastMenuItem._setHighlighted(true);
          lastMenuItem._getHostElement().classList.add('red-text');
        }
      }, 100);
    }
  }

  showFileActions(action: string) {
    const { fileActionsMenu } = this.clientProfileToolbarComponent;

    let nativeElement;
    if (fileActionsMenu && (nativeElement = fileActionsMenu['_elementRef'].nativeElement)) {
      nativeElement.classList.add('show');
      nativeElement.click();
      // this.showHelpPanel();
    } else {
      console.log('fileActionsMenu is not defined');
    }
  }

  hideFileActions() {
    const { fileActionsMenu } = this.clientProfileToolbarComponent;
    let nativeElement;
    if (fileActionsMenu && (nativeElement = fileActionsMenu['_elementRef'].nativeElement)) {
      // nativeElement.classList.remove('show');
      nativeElement.click();
    } else {
      console.log('fileActionsMenu is not defined');
    }
  }

  private async refreshCaseFolders() {
    this.caseFolders = await this.firebase_utilities_$.getFolders(this.casename);
  }

  private selectFile(fileId: string) {
    console.log('>>>>>>>>>>>>>>>>> :', fileId);

    const fileToSelect = this.filesTableDataSource.data.find(
      file => file.fileId === fileId || file.DICOMDIRFileId === fileId,
    );

    if (!fileToSelect) {
      return false;
    }

    this.handleRowClick(fileToSelect, new Event('click'), { currentFolder: this.currentFolder });

    return fileToSelect;
  }

  private handleError = (error?) => {
    console.trace('Error generating the disc download link.', error);
    const errorMessage = 'Error generating the disc download link. Please try again or contact support@nuagedx.com.';
    if (!error) console.error(errorMessage);
    else console.error(errorMessage, error);
    this.uiMessaging_$?.toastMessage(errorMessage, 'ERROR');
  };
  generateDownloadDisc(fileSelected) {
    console.log('generateDownloadDisc:');

    // this.showHelpPanel();

    this.gapiOperations_$.downloadDICOMFiles(fileSelected, this.auth_$.userData.getValue()['uid']).subscribe({
      next: (res: { message: string }) => {
        console.log('res message:', res.message);
        /**
         * NOTE: Please do not forget start the waiting UI notification at this point.
         * The user will be notified when the file is ready to be downloaded.
         * Please do not keep the user waiting for too long.
         * Please do not keep the user "WONDERING" about the status of the process.
         */
        this.startWaitingForDiscGeneration(fileSelected.fileId);
        this.sessionStorage_$.clearStoppedGeneratedDiscAlert();
      },
      error: (error: any) => {
        this.hideStartGeneratingDiscNotification(fileSelected.fileId);
        this.handleError(error);
      },
      complete: () => console.log('completed.'),
    });
  }

  private whenUnShareFilesAreDone(result, email) {
    this.sendFileUnSharingEmailNotification({ email, casename: this.casename, files: result.join(', ') });
    this.handleStoreLog(result, email);
    this.getFilesForPatient(this.currentFolder, this.getOptions());
    this.evaluateConsultantsListsUpdate();
  }

  private sendFileUnSharingEmailNotification({ email, casename, files }) {
    const messageData = { targetEmail: email, caseName: casename, files: files };
    this.firebase_utilities_$.sendEmailNotification(messageData, 'unshare');
  }

  async shareSelectedFilesWith({ email, uid }) {
    if (this.patient.restrictedProfiles?.length && this.patient.restrictedProfiles.includes(uid))
      console.log('This user is restricted', email);
    else if (this.patient.allowedProfiles?.length && this.patient.allowedProfiles.includes(uid))
      console.log('This user is allowed', email);

    Promise.all(this.generateShareFilePromises(email)).then(result => {
      this.whenShareFilesAreDone(result, email);
    });
  }

  private async getRefreshOptions() {
    return {
      folderName: this.currentFolderName,
      caseFolders: await this.getCaseFolders(),
      roleConsultant: this.userIsConsultant,
      roleClient: this.userIsClient,
    };
  }

  private getOwnerTotalStudies() {
    return this.firebase_utilities_$.getOwnerTotalStudies(this.patient.ownerID);
  }

  getOwnerPlanCode() {
    return this.patient.ownerPlanCode;
  }
}
