<app-window-title [bottomBorder]="false"
                  [closable]="true"
                  [title]="'Client/Matter Profile' + ' ' + patientName"></app-window-title>
<div style="margin-bottom: 15px"
     fxLayout="row"
     fxLayout.sm="column"
     fxLayout.xs="column">
  <div class="client-matter-data"
       fxFlex
       fxLayout="row wrap"
       [ngClass]="'mat-body'">
    <app-client-matter-data-info *ngIf="patient"
                                 [patient]="patient"></app-client-matter-data-info>
    <button *ngIf="
        realRole === UserRoles.owner &&
        (!patient?.clientUser || patient.clientUser.length === 0) &&
        plancode !== 'silver'
      "
            color="warn"
            mat-flat-button
            (click)="handleAddClientUser($event)">
      <mat-icon>
        person_add
      </mat-icon>
      Add Client Upload Account
    </button>
  </div>
  <!-- <div fxFlex="none">
    <button
      mat-flat-button
      mat-dialog-close
      color="warn">
      X
    </button>
  </div> -->
</div>
<div *ngIf="patientName !== '...'"
     style="height: 75vh; width: 100%">
  <div style="height: 100%"
       fxLayout="column">
    <div fxLayout="row"
         fxLayoutAlign="space-between start"
         fxLayoutGap="15px grid">
      <div fxLayout="column">
        <div *ngIf="loadingPermissions"
             style="margin-bottom: 10px"
             fxLayout="row"
             fxLayoutAlign="start center">
          <app-loader></app-loader>
        </div>

        <!-- TOP BUTTONS -->
        <app-client-profile-top-buttons [clioMatterId]="clioMatterId"
                                        [currentFolderName]="currentFolderName"
                                        [importFromAppsBtn]="importFromAppsBtn"
                                        [permissions_uploadfiles]="permissions_uploadfiles"
                                        [realRole]="realRole"
                                        (addConsultantEvent)="addConsultant()"
                                        (backToClioEvent)="backToClio($event)"
                                        (importFromAppsEvent)="showImportFromApps()"
                                        (openDialogEvent)="openDialog($event)"></app-client-profile-top-buttons>
        <br>

        <!-- BREADCRUMB -->
        <app-breadcrumb *ngIf="UserRoles.client !== realRole"
                        [currentFolder]="currentFolder"
                        [currentFolderName]="currentFolderName"
                        [folderColor]="folderColor"
                        (buttonClick)="handleBreadcrumbClick($event)"></app-breadcrumb>

        <!-- HELP TEXT -->
        <app-client-profile-info-box [currentFolder]="currentFolder"
                                     [currentFolderName]="currentFolderName"
                                     [filesTableDataSource]="filesTableDataSource"
                                     [permissions_uploadfiles]="permissions_uploadfiles"
                                     [realRole]="realRole"
                                     [UserRoles]="UserRoles"></app-client-profile-info-box>

        <!-- TOOLBAR -->
        <app-client-profile-toolbar [activeConsultant]="activeConsultant"
                                    [activeConsultant]="activeConsultant"
                                    [allowDeleteNote]="allowDeleteNote"
                                    [currentFolder]="currentFolder"
                                    [currentFolderName]="currentFolderName"
                                    [fileActionsMenuItems]="fileActionsMenuItems"
                                    [files]="files"
                                    [fileSelected]="fileSelected()"
                                    [filesFilter]="filesFilter"
                                    [permissions_createfolder]="permissions_createfolder"
                                    [realRole]="realRole"
                                    [refreshButton]="refreshButton"
                                    [selection]="selection"
                                    [sortTypes]="sortTypes"
                                    [unshareAllFilesMsg]="unshareAllFilesMsg"
                                    [UserRoles]="UserRoles"
                                    (createFolderEvent)="createFolder(currentFolder, { folderName: currentFolderName })"
                                    (handleRefreshEvent)="handleRefresh()"
                                    (handleSelectEvent)="handleSelect($event)"
                                    (openAddNoteDialogEvent)="openAddNoteDialog()"
                                    (openDeleteConfirmDialogEvent)="openDeleteConfirmDialog()"
                                    (openFileSearchEvent)="openFileSearch()"
                                    (removeFilterEvent)="removeFilter()"
                                    (sortFilesBySelectedEvent)="sortFilesBySelected()"
                                    (unShareAllEvent)="unShareAll($event)"></app-client-profile-toolbar>
      </div>
      <app-notifications fxLayoutGap="15px"
                         [notifications]="clientProfileNotifications"
                         (clickAction)="onNotificationClick($event)"></app-notifications>
    </div>

    <div fxLayout="row">
      <div fxFlex
           fxLayout="column">
        <div *ngIf="filesTableDataSource.data"
             class="files-table"
             [ngClass]="'mat-elevation-z0 stickyheader-table-container table-big-container'">
          <div *ngIf="filesTableDataSource.data.length === 0 && loading === false"
               class="no-files-message">
            <h2 [ngClass]="'mat-body'">
              {{nofilesMessage}}
            </h2>
          </div>
          <app-loader *ngIf="loading"
                      [message]="loadingMessage || ''"></app-loader>
          <div *ngIf="filesTableDataSource.data.length > 0; else nofiles"
               [ngClass]="'table-container'">
            <table mat-table
                   matSort
                   matSortActive="fileName"
                   matSortDirection="desc"
                   matSortDisableClear
                   [dataSource]="filesTableDataSource"
                   [ngClass]="'mat-elevation-z1'"
                   (matSortChange)="handleSortData($event)">
              <!-- Checkbox Column -->
              <ng-container matColumnDef="select">
                <th *matHeaderCellDef
                    mat-header-cell
                    [ngClass]="{ hidden: currentFolder === '' }">
                  <mat-checkbox [checked]="selection.hasValue() && isAllSelected()"
                                [indeterminate]="selection.hasValue() && !isAllSelected()"
                                (change)="$event ? masterToggle() : null">
                  </mat-checkbox>
                </th>
                <td *matCellDef="let element"
                    mat-cell
                    [ngClass]="{ hidden: currentFolder === '' }">
                  <mat-checkbox [checked]="selection.isSelected(element)"
                                (change)="$event ? selection.toggle(element) : null"
                                (click)="handleCheckboxClick($event)">
                  </mat-checkbox>
                </td>
              </ng-container>
              <!-- File Name Column -->
              <ng-container matColumnDef="fileName">
                <th *matHeaderCellDef
                    mat-header-cell
                    mat-sort-header="fileName"
                    [ngClass]="'bold-black-table-header'">
                  <span *ngIf="currentFolder !== '' && currentFolderName !== 'Discs' && realRole !== 'Consultant'">
                    Files you have uploaded
                  </span>
                  <span *ngIf="currentFolderName === 'Discs'">
                    Discs files you have generated
                  </span>
                  <span *ngIf="currentFolder !== '' && realRole === 'Consultant'">
                    Files you have available for review:
                  </span>
                  <span *ngIf="currentFolder === ''">
                    Your folders
                  </span>
                </th>
                <td *matCellDef="let element"
                    mat-cell>
                  <span *ngIf="checkInactiveStore(element)"
                        class="loading-message">
                    <mat-icon aria-hidden="false"
                              aria-label="preview icon">
                      preview
                    </mat-icon>
                    &nbsp; Study loading...
                  </span>
                  <a *ngIf="!element.viewerurl && element.type !== 'folder'"

                     download
                     target="_blank"

                     attr.data-storename="{{ element.storename }}"
                     mat-flat-button
                     matTooltip="Click here to browse the file in a new tab."

                     [ngClass]="'folder-button'"
                     (click)="handleFileClick($event, element)">
                    <mat-icon [ngClass]="element.hasNotifications ? 'blinking-bell' : 'hidden'">
                      fiber_manual_record
                    </mat-icon>
                    {{element.fileName}}
                  </a>
                  <button *ngIf="element.clioDocId"
                          mat-flat-button
                          matTooltip="Open this Document in Clio"
                          (click)="openDocumentInClio(element.clioDocId)">
                    <img alt="Clio logo"
                         src="./../../../assets/png/clio-blue-square.png"
                         width="15px">
                  </button>
                  <a *ngIf="element.type === 'folder'"

                     mat-flat-button
                     matTooltip="Click here to browse the folder contents."

                     [ngClass]="'folder-button'"
                     [ngStyle]="getButtonStyle(element.name)"
                     (click)="handleFolderClick($event, element)">
                    <mat-icon class="material-icons-outlined"
                              aria-hidden="false"
                              aria-label="Example home icon">
                      folder
                    </mat-icon>
                    {{ element.label || element.name }}
                  </a>
                  <mat-icon *ngIf="element.type === 'folder'"
                            [ngClass]="element.hasNotifications ? 'blinking-bell' : 'hidden'">
                    notifications
                  </mat-icon>

                  <mat-icon *ngIf="element.viewerurl && element.type !== 'folder'"
                            [ngClass]="element.hasNotifications ? 'blinking-bell' : 'hidden'">
                    fiber_manual_record
                  </mat-icon>

                  <button *ngIf="element.viewerurl && !checkInactiveStore(element)"
                          matTooltip="Click here to browse the study in the DICOM viewer."
                          [ngClass]="{ 'view-link': true }"
                          [ngStyle]="{ display: 'inline-flex', verticalAlign: 'middle' }"
                          (click)="handleFileClick($event, element)">
                    <mat-icon aria-hidden="false"
                              aria-label="preview icon">
                      preview
                    </mat-icon>
                    {{
                      element.filePath?.indexOf(dicomdirFileName) >= 0 || element.fileName === dicomdirFileName
                        ? 'Imaging Study'
                        : element.fileName
                    }}
                  </button>
                  <mat-icon *ngIf="element.sharedUsers && element.sharedUsers.length > 0 && permissions_sharefileicon"

                            aria-hidden="false"
                            aria-label="This file is shared."
                            matTooltip="This file is shared."

                            [ngClass]="'file-icon'"
                            (click)="toggleHighLight($event, element)">
                    people
                  </mat-icon>

                  <!-- Place a waiting animation here -->
                  <mat-spinner *ngIf="element.waiting"
                               style="margin-left: 10px; display: inline-block; vertical-align: middle"
                               [color]="'warn'"
                               [diameter]="20"
                               [strokeWidth]="4"></mat-spinner>

                  <!-- Place a DONE icon when waiting is done -->
                  <mat-icon *ngIf="element.waiting === false && element.downloadLink"

                            aria-hidden="false"
                            aria-label="This zipped contents are ready for download."
                            matTooltip="This zipped contents are ready for download."

                            [color]="'warn'"
                            [ngClass]="'file-icon'">
                    download
                  </mat-icon>

                  <mat-icon *ngIf="element.notes && element.notes.length && validateProfile(element.notes)"

                            aria-hidden="false"
                            aria-label="sticky_note_2 icon"
                            matTooltip="This file has note(s)."

                            [color]="
                      element.newNoteNotifications?.length || element.modifiedNoteNotifications?.length ? 'warn' : ''
                    "
                            [disabled]="!element.notes.length || !element.disabled"
                            [ngClass]="'file-icon'"
                            [ngClass]="
                      element.newNoteNotifications?.length || element.modifiedNoteNotifications?.length
                        ? 'blinking-note'
                        : ''
                    "
                            [ngClass]="{ disabled: element.disabled }"
                            (click)="handleNoteClick($event, element)">
                    sticky_note_2
                  </mat-icon>
                </td>
              </ng-container>
              <ng-container matColumnDef="fileDesc">
                <th *matHeaderCellDef
                    mat-header-cell
                    mat-sort-header="fileDesc"
                    [ngClass]="{ hidden: currentFolder === '' }">
                  File content
                </th>
                <td *matCellDef="let element"
                    mat-cell
                    [ngClass]="{ hidden: currentFolder === '' }">
                  {{ element.type !== 'folder' ? element.fileDesc : '' }}
                </td>
              </ng-container>
              <!-- Last Modified Column -->
              <ng-container matColumnDef="lastModified">
                <th *matHeaderCellDef
                    mat-header-cell
                    mat-sort-header="lastModified"
                    [ngClass]="{ hidden: currentFolder === '' }">
                  Last Modified
                </th>
                <td *matCellDef="let element"
                    mat-cell
                    [ngClass]="{ hidden: currentFolder === '' }">
                  {{ element.type !== 'folder' ? (element.lastModified ? element.lastModified : '') : '' }}
                </td>
              </ng-container>
              <!-- Date Uploaded Column -->
              <ng-container matColumnDef="uploadedDate">
                <th *matHeaderCellDef
                    mat-header-cell
                    mat-sort-header="uploadedDate"
                    [ngClass]="{ hidden: currentFolder === '' }">
                  {{ currentFolderName === discsFolderName ? 'Date Created' : 'Date Uploaded' }}
                </th>
                <td *matCellDef="let element"
                    mat-cell
                    [ngClass]="{ hidden: currentFolder === '' }">
                  {{ element.type !== 'folder' ? element.uploadedDate : '' }}
                </td>
              </ng-container>
              <!-- Date of File Column -->
              <ng-container matColumnDef="fdate">
                <th *matHeaderCellDef
                    mat-header-cell
                    mat-sort-header="fdate"
                    [ngClass]="{ hidden: currentFolder === '' }">
                  Date of File
                </th>
                <td *matCellDef="let element"
                    mat-cell
                    [ngClass]="{ hidden: currentFolder === '' }">
                  {{
                    element.type !== 'folder'
                      ? element.fdate
                        ? element.ftime
                          ? element.fdate + ' - ' + element.ftime
                          : element.fdate
                        : ''
                      : ''
                  }}
                </td>
              </ng-container>
              <!-- File parentFolderName Column -->
              <ng-container matColumnDef="parentFolderName">
                <th *matHeaderCellDef
                    mat-header-cell
                    [ngClass]="{ hidden: currentFolder !== 'all' }">
                  File Folder Name
                </th>
                <td *matCellDef="let element"
                    mat-cell
                    [ngClass]="{ hidden: currentFolder !== 'all' }">
                  <a mat-flat-button
                     matTooltip=" {{ element.parentFolderName || element.parentFolder || '-' }}"
                     [ngClass]="['folder-button', 'short']"
                     [ngStyle]="getButtonStyle(element.parentFolderName || element.parentFolder)"
                     (click)="handleFolderNameClick($event, element)">
                    <mat-icon class="material-icons-outlined"
                              aria-hidden="false"
                              aria-label="Example home icon">
                      folder
                    </mat-icon>
                    {{ element.parentFolderName || element.parentFolder || '-' }}
                  </a>
                  <div *ngIf="element?.parentFolders">
                    <a *ngFor="let folder of element.parentFolders; let i = index"

                       mat-flat-button
                       matTooltip=" {{ folder }}"

                       [ngClass]="['folder-button', 'short']"
                       [ngStyle]="getButtonStyle(folder.folderName)"
                       (click)="handleParentFolderNameClick($event, folder)">
                      <mat-icon class="material-icons-outlined"
                                aria-hidden="false"
                                aria-label="Folder icon">
                        folder
                      </mat-icon>
                      {{ folder.folderName || folder }}
                    </a>
                  </div>
                </td>
              </ng-container>
              <!-- File ID Column -->
              <ng-container matColumnDef="fileId">
                <th *matHeaderCellDef
                    class="table-actions-column"
                    mat-header-cell>
                  File actions
                </th>
                <td *matCellDef="let element"
                    mat-cell>
                  <button *ngIf="
                      permissions_editfiles &&
                      element.folderId !== 'all' &&
                      element.predefined !== true &&
                      currentFolderName !== discsFolderName
                    "

                          aria-label="Click here to edit this upload."
                          mat-icon-button
                          matTooltip="Click to edit this file."

                          [ngClass]="'small-mat-icon-button'"
                          (click)="renameElement(element, $event)">
                    <mat-icon>
                      create
                    </mat-icon>
                  </button>
                  <button *ngIf="
                      sysadmin &&
                      permissions_editfiles &&
                      element.folderId !== 'all' &&
                      element.predefined !== true &&
                      element.type === 'folder'
                    "

                          aria-label="Click to delete this folder."
                          mat-icon-button
                          matTooltip="Click to delete this folder."

                          [ngClass]="'small-mat-icon-button'"
                          (click)="deleteFolder(element, $event)">
                    <mat-icon>
                      delete
                    </mat-icon>
                  </button>

                  <button *ngIf="permissions_deletefiles && element.type !== 'folder'"

                          aria-label="Click here to delete this from the files you have uploaded."
                          mat-icon-button
                          matTooltip="Click here to delete this from the files you have uploaded."

                          [ngClass]="'small-mat-icon-button'"
                          (click)="deleteSingleRow(element, $event)">
                    <mat-icon>
                      delete
                    </mat-icon>
                  </button>
                </td>
              </ng-container>
              <!-- File predefined Column -->
              <ng-container matColumnDef="predefined">
                <th *matHeaderCellDef
                    class="hidden"
                    mat-header-cell>
                  predefined
                </th>
                <td *matCellDef="let element"
                    class="hidden"
                    mat-cell>
                  {{ element.predefined }}
                </td>
              </ng-container>
              <!-- File Shared Column -->
              <ng-container matColumnDef="isShared">
                <th *matHeaderCellDef
                    mat-header-cell
                    mat-sort-header="isShared"
                    [ngClass]="{
                    hidden: !activeConsultant || realRole === 'Consultant'
                  }">
                  Shared status
                </th>
                <td *matCellDef="let element"
                    mat-cell
                    [ngClass]="{
                    hidden: !activeConsultant || realRole === 'Consultant'
                  }">
                  <span *ngIf="activeConsultant !== null && !fileSharedValidation(element) && !checkIfSelected(element)"
                        class="not-shared-message">
                    Not shared
                  </span>
                  <button *ngIf="activeConsultant !== null && !fileSharedValidation(element) && checkIfSelected(element)"
                          color="warn"

                          mat-flat-button
                          matTooltip="Click here to share this file with active consultant."

                          [ngClass]="{ grayed: !checkIfSelected(element) }"
                          (click)="shareSingleFile(element, $event)"> 
                          <mat-icon>share</mat-icon>
                          Share
                  </button>
                  <span *ngIf="activeConsultant && fileSharedValidation(element) && !checkIfSelected(element)">
                    Already shared
                  </span>
                  <button *ngIf="activeConsultant && fileSharedValidation(element) && checkIfSelected(element)"

                          mat-flat-button
                          matTooltip="Click here to stop sharing this file with active consultant."

                          [ngClass]="{ blushed: checkIfSelected(element) }"
                          (click)="$event.stopPropagation(); unShareSingleFile(element)">
                    Unshare
                  </button>
                </td>
              </ng-container>
              <tbody>
                <tr *matHeaderRowDef="displayedColumns; sticky: true"
                    mat-header-row></tr>
                <tr *matRowDef="let element; columns: displayedColumns; let i = index"
                    mat-row
                    [ngClass]="{
                    highlighted: fileSharedValidation(element) && realRole !== 'Consultant',
                    'inactive-store': checkInactiveStore(element)
                  }"
                    (click)="
                    isDisplayContextMenu = false;
                    handleRowClick(element, $event, {
                      currentFolder: currentFolder
                    })
                  "></tr>
              </tbody>
            </table>
          </div>
          <ng-template #nofiles>
            <div class="no-files">
              <p style="color: red; font-size: 12px">
                No files found
              </p>
            </div>
          </ng-template>
          <mat-paginator *ngIf="pageSize"

                         color="warn"
                         showFirstLastButtons

                         [length]="pagesLength"
                         [pageSize]="pageSize"
                         [pageSizeOptions]="pageSizes"></mat-paginator>
        </div>
      </div>

      <div *ngIf="
          permissions_consultantslist &&
          filesTableDataSource.data.length > 0 &&
          currentFolder !== '' &&
          currentFolderName !== discsFolderName
        "
           fxFlex="29"
           fxFlexOffset="1">
        <h4>
          Consultants & Users
        </h4>
        <mat-tab-group #consultants_list_tab_group
                       color="warn"
                       [ngClass]="['custom-tab-label', 'consultants-list-tab-group']"
                       [(selectedIndex)]="selectedTabIndex"
                       (selectedTabChange)="handleTabsClick($event)">
          <mat-tab *ngIf="activeConsultants"
                   #active_consultants_tab
                   label="Active">
            <p class="active-consultants-message mat-body">
              Click Consultant to see which files are Shared with them.
            </p>
            <app-active-consultants *ngIf="activeConsultants"
                                    #active_consultants

                                    [allowedProfiles]="patient.allowedProfiles"
                                    [caseName]="patient.caseName"
                                    [dataFromParent]="activeConsultants"
                                    [patientDocId]="patient.patientDocId"
                                    [restrictedProfiles]="patient.restrictedProfiles"
                                    [selectedFiles]="selection.selected"
                                    [sharedFilesCount]="sharedFilesCount"

                                    (goToFolderEvent)="goToFolder($event)"
                                    (needConsultantsReloadEvent)="reloadConsultantsData($event)"
                                    (rowClickEvent)="handleActiveConsultantClick($event)"
                                    (shareEvent)="shareSelectedFilesWith($event)"
                                    (unshareEvent)="unShareWith($event)"></app-active-consultants>
          </mat-tab>
          <mat-tab *ngIf="availableConsultants && currentFolder !== ''"
                   #available_consultants_tab
                   label="Available">
            <app-available-consultants *ngIf="availableConsultants"
                                       #available_consultants

                                       [availableConsultants]="availableConsultants"
                                       [caseName]="patient.caseName"

                                       (parentReload)="clientProfileReload($event)"
                                       (shareEvent)="shareSelectedFilesWith($event)">
            </app-available-consultants>
          </mat-tab>
        </mat-tab-group>

        <button *ngIf="activeConsultant && realRole !== 'Consultant'"
                color="warn"

                gdAlignColumns
                mat-flat-button

                [disabled]="!activeConsultant"
                (click)="unShareAll(activeConsultant)">
          {{ unshareAllFilesMsg }}: {{ activeConsultant }}
        </button>
      </div>
    </div>
  </div>
  <div *ngIf="infectedFiles && infectedFiles.length"
       class="mat-small">
    <p>
      The followinfg file(s) has been deleted because of malware found in it. Please click
      <a class="refresh-link"
         href="#"
         (click)="goRefresh($event)">
        REFRESH
      </a>
      to update the list of files
    </p>
    <ul>
      <li *ngFor="let file of infectedFiles"
          class="infected-file">
        {{ file }}
      </li>
    </ul>
  </div>
</div>
